import React, { useContext, useEffect, useState } from 'react'
import { faFile, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, ListGroup, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import { isConsultor, isModuleConsultor, isModuleOrganizacion, isVerificador } from '../../helpers/helpers'
import LacunaWebPKI from 'web-pki';
import ModalPlugin from '../../components/ModalPlugin/ModalPlugin'
import { GetIncidenciasJSON } from '../../services/incidencia.service'
import { Loader } from '../../components/Loader/Loader'
import { GetConsultorJSON } from '../../services/consultor.service'
import { useAxios } from '../../hooks/useAxios'
import {ContenidosPublic} from '../Contenidos/ContenidosPublic/ContenidosPublic'

export const DashboardConsultor = () => {

    const history = useHistory()

    const { user } = useContext(AuthContext);

    // Plugin de firmado

    // Web pki

    const [pki, setPki] = useState()

    const [readyPKI, setreadyPKI] = useState(isVerificador(user?.perfil) ? true : false)

    const [isInstalled, setIsInstalled] = useState(true)

    // Instalar plugin

    const [showModalPlugin, setShowModalPlugin] = useState(false);

    const handleCloseModalPlugin = () => setShowModalPlugin(false);
    const handleShowModalPlugin = () => setShowModalPlugin(true);

    function onWebPkiNotInstalled(status, message) {
        console.log('InstalÂ·laciÃ³ incorrecta: ' + message);
        setreadyPKI(false)
        setIsInstalled(false)
    }

    function onWebPkiReady() {
        console.log('Component ready.');
        setreadyPKI(false)
    }

    function onWebPkiError(message, error, origin) {
        console.log('Web PKI error originat a ' + origin + ': ' + error);
        setreadyPKI(false)
    }

    useEffect(() => {

        if (isVerificador(user?.perfil)) {
            let pkiT = new LacunaWebPKI({
                "format": 2,
                "allowedDomains": [
                    "*.arescoop.es"
                ],
                "homologDomains": [
                    "ip4:10.0.0.0/8",
                    "ip4:127.0.0.0/8",
                    "ip4:172.16.0.0/12",
                    "ip4:192.168.0.0/16"
                ],
                "productLevel": "Pro",
                "expiration": "2022-12-31 03:00:00Z",
                "signature": "rdSl3ekA8zA3+iZgN/SbGP/6z+XH5V4ttn2Ww7FiQhoCqVaOQGpALoSpvUnn7/sskg94b3J5eF4GjnxYJv/qcGopKouHM1AMZVy3pMyR8X/03U3FC0GCWWJS+Ushqwsc0ZD+Aw+LF7Q5SxBsOIFKAoHMQ6BRnnzi1NqPWYroiwWBdcoCfn+hhr2DhtDirNp16FdNbM2ntWoAO9PF3uoixwFqluUxeDbcynHIhplgFChksxlilMNBlknxHIzaZf6SpcWLUy0L/z5DTn6FIafAOlPaA4sv6UESp1LlQLlBKoXMJFLyHHdxXEQOHE1KHjhdOlR9Fz+A4xt6O1M9nJGivg=="
            });

            setPki(pkiT)
        }

    }, [])

    useEffect(() => {

        if (pki) {

            pki.init({
                ready: onWebPkiReady,
                notInstalled: onWebPkiNotInstalled,
                defaultError: onWebPkiError
            });
        }

    }, [pki])

    // Incidencias

    const [fetchIncidencias, fetchIncidenciasData] = useAxios('get', `/incidencias/dapcons-incidencias`)

    useEffect(() => {

        fetchIncidencias({
            params: {
                esta_resuelta: false,
                perfil: 'consultor',
                idioma_id: 1
            }
        })

    }, [])

    useEffect(() => {
        console.log(fetchIncidenciasData)
    }, [fetchIncidenciasData])

    const [fetchConsultor, fetchConsultorData] = GetConsultorJSON()

    useEffect(() => {

        fetchConsultor({
            params: {
                consultor_nif: user?.user?.usuario
            }
        })

    }, [])

    // useEffect(() => {

    //     if (fetchConsultorData.status === 200) {

    //         handleFormChangeConsultor(fetchConsultorData.data)
    //     }

    // }, [fetchConsultorData])


    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Inicio
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="col-12">
                <div className="row justify-content-end">
                    <div className="col flex-grow-0 text-nowrap">
                        <Alert variant='light' className='cursor-pointer border py-2' onClick={() => window.open('./20231222_DAPcons_RCP100_v3.2.pdf')}>
                            <FontAwesomeIcon icon={faFile} className='me-2' /> REGLAS DE CATEGORÍA DE PRODUCTO (RCP)
                        </Alert>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-4 mb-xl-0">

                {
                    isVerificador(user?.perfil) && !isInstalled &&
                    <Alert variant='warning'>
                        Para poder firmar los DAPcons es necesario instalar el Plugin de firma, <Alert.Link onClick={handleShowModalPlugin}>haga click aquí</Alert.Link> para instalarlo
                    </Alert>
                }
                {
                    (fetchIncidenciasData.loading || fetchConsultorData.loading) ?

                        <div className='text-center' colSpan={5}>
                            <Loader />
                        </div>
                        :
                        fetchIncidenciasData.data?.data?.data?.total > 0 &&
                        <>
                            {
                                !fetchConsultorData.data?.logo &&
                                <Alert variant='warning'>
                                    No está definido el logotipo del consultor, <Alert.Link onClick={() => history.push('/mis-datos')}>haga click aquí</Alert.Link> para añadirlo
                                </Alert>
                            }

                            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">

                                <div className="d-flex justify-content-between">
                                    <div className='flex-grow-1'>
                                        <h5 className='fs-105'>Incidencias</h5>
                                        <ListGroup variant="flush" className='ListDashboard'>
                                            {
                                                fetchIncidenciasData.data?.data?.data?.data?.map(item => {
                                                    return (
                                                        <ListGroup.Item onClick={() => history.push({
                                                            pathname: `/DAPcons/${item.id}/${item.incidencias?.[0]?.idioma_id}`
                                                        })}> <strong className='fw-500 pe-2 cursor-pointer'>{item.created_at?.split('T')?.[0]}</strong> Incidencia en el DAPcon {item.dapcons_codigo ? item.dapcons_codigo : item.dapcons_id}</ListGroup.Item>
                                                    )
                                                })
                                            }
                                        </ListGroup>
                                    </div>
                                </div>
                            </div>
                        </>
                }

            </div>

            <div className="col-12">
                <ContenidosPublic/>
            </div>

            {/* <div className="col-xl-4">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                    <div className="d-flex justify-content-between">
                        <div className='flex-grow-1'>
                            <h5>Links de interés</h5>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Enlace de interes 1</ListGroup.Item>
                                <ListGroup.Item>Enlace de interes 2</ListGroup.Item>
                                <ListGroup.Item>Enlace de interes 3</ListGroup.Item>
                                <ListGroup.Item>Enlace de interes 4</ListGroup.Item>
                                <ListGroup.Item>Enlace de interes 5</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </div> */}
            {
                readyPKI && <div id="cover-spin2" className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
            <ModalPlugin
                handleCloseModalPlugin={handleCloseModalPlugin}
                handleShowModalPlugin={handleShowModalPlugin}
                showModalPlugin={showModalPlugin}
                pki={pki}
            />
        </div>

    )
}
