import { faCheckCircle, faFile, faFilePdf, faHome, faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Breadcrumb, Form, ListGroup } from 'react-bootstrap'
import { getOrganizacionAdapterSchemaMisDatos } from '../../adapters/organizacion.adapter'
import { AuthContext } from '../../auth/AuthContext'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { FileList } from '../../components/FileList/FileList'
import { InputFile, InputSelect, InputText } from '../../components/Inputs'
import InputImage from '../../components/Inputs/InputImage'
import { Loader } from '../../components/Loader/Loader'
import { ModalCropImage } from '../../components/ModalCropImage/ModalCropImage'
import { ModalStatus } from '../../components/ModalStatusServer/ModalStatus'
import { ModalStatusError } from '../../components/ModalStatusServer/ModalStatusError'
import { MsgInput } from '../../components/MsgInput/MsgInput'
import { getDataFile, isStatusSucess } from '../../helpers/helpers'
import { useAxios } from '../../hooks/useAxios'
import { useForm } from '../../hooks/useForm'
import { useValidator } from '../../hooks/useValidator'
import { GetOrganizacionJSON, PostOrganizacionJSON } from '../../services/organizacion.service'
import { GetPaisJSON } from '../../services/pais.service'

export const MisDatosOrganizacion = () => {

    const { user } = useContext(AuthContext);

    const [fetchOrganizacion, fetchOrganizacionData, resetFetchOrganizacion] = GetOrganizacionJSON()

    useEffect(() => {

        fetchOrganizacion({
            params: {
                org_cif: user.user.usuario
            }
        })

    }, [])

    useEffect(() => {

        if (fetchOrganizacionData.status === 200) {

            handleFormChangeOrganizacion(fetchOrganizacionData.data)
        }

    }, [fetchOrganizacionData])


    const [
        formOrganizacion,
        handleInputChangeOrganizacion,
        handleFormChangeOrganizacion,
        handleResetOrganizacion
    ] = useForm(getOrganizacionAdapterSchemaMisDatos())

    useEffect(() => {

        console.log(fetchOrganizacionData)

    }, [fetchOrganizacionData])

    const [fetchSendOrganizacion, fetchSendOrganizacionData] = PostOrganizacionJSON()

    const handleSubmitActualizarEmpresa = () => {

        if (handleVerifiedValuesRegisterEmpresa(formOrganizacion)) {

            fetchSendOrganizacion({ body: formOrganizacion })
        }
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchSendOrganizacionData)

        if (fetchSendOrganizacionData?.status === 201) {
            setShowSuccessEdit(true)
        }

    }, [fetchSendOrganizacionData])

    const [valuesValidRegistroEmpresa, handleVerifiedValuesRegisterEmpresa, handleResetValuesRegisterEmpresa] = useValidator({
        cif: { isOk: true, msgError: [`El documento es obligatorio.`] },
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        direccion: { isOk: true, msgError: [`La dirección es obligatoria.`] },
        codigo_postal: { isOk: true, msgError: [`El código postal es obligatorio.`] },
        poblacion: { isOk: true, msgError: [`La población es obligatoria.`] },
        pais: { isOk: true, msgError: [`El país es obligatorio.`] },
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
        telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },

        persona_contacto_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        persona_contacto_cargo: { isOk: true, msgError: [`El cargo es obligatorio.`] },
        persona_contacto_telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },
        persona_contacto_email: { isOk: true, msgError: [`El email es obligatorio.`] },

        contacto_facturacion_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        contacto_facturacion_email: { isOk: true, msgError: [`El email es obligatorio.`] },
        contacto_facturacion_telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },
    })

    const [fetchGetImage, fetchGetImageData] = useAxios('get', `/org-logo-img`, '', 'blob')

    useEffect(() => {

        if (formOrganizacion.logo && typeof formOrganizacion.logo === 'string') {

            fetchGetImage({
                id: `${formOrganizacion.id}/img?token_img=${formOrganizacion.logo}`
            })
        }

    }, [formOrganizacion.logo])

    useEffect(() => {

        if (fetchGetImageData.data) {

            console.log(fetchGetImageData.data.data)
            setInputFiles([new File([fetchGetImageData.data?.data], 'logo.png')])
        }

    }, [fetchGetImageData])

    // Recortar imagen

    const [InputFiles, setInputFiles] = useState([])

    useEffect(() => {

        handleFormChangeOrganizacion({
            ...formOrganizacion,
            logo: InputFiles?.[0],
        })

    }, [InputFiles])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }

    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)
    // const [fetchPoblaciones, fetchPoblacionesData] = getPoblacionesJSON()()

    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (activeEspana) {

            if (formOrganizacion.codigo_postal?.length === 5) {

                fetchPoblaciones({
                    params: {
                        cp: formOrganizacion.codigo_postal
                    }
                })
            } else {
                setPoblaciones([])
                handleFormChangeOrganizacion({
                    ...formOrganizacion,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }
        }

    }, [formOrganizacion.codigo_postal])

    useEffect(() => {

        console.log(fetchPoblacionesData)

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data?.status === 201) {

                const poblaciones = fetchPoblacionesData.data?.data?.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeOrganizacion({
                    ...formOrganizacion,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }
        }
        if (fetchPoblacionesData.error){
            handleFormChangeOrganizacion({
                ...formOrganizacion,
                provincia: '',
                poblacion: '',
                // pais: '',
            })
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formOrganizacion.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data.data]

            console.log(poblacionesTemp)

            const resp = poblacionesTemp.find(item => item.poblacion === formOrganizacion.poblacion)

            handleFormChangeOrganizacion({
                ...formOrganizacion,
                provincia: resp?.provincia,
                // pais: resp?.pais,
            })
        }

    }, [formOrganizacion.poblacion, fetchPoblacionesData.data])


    // Registro subir archivos

    const [fetchRegistroEmpresaFileISO, fetchRegistroEmpresaFileISOData] = useAxios('post', `/organizaciones/actualizar/documentos?_method=PATCH`)
    const [fetchRegistroEmpresaFileEMACS, fetchRegistroEmpresaFileEMACSData] = useAxios('post', `/organizaciones/actualizar/documentos?_method=PATCH`)

    const [formOrganizacionFiles, handleInputChangeRegisterEmpresaFiles,
        handleFormChangeRegisterEmpresaFiles, handleResetRegisterEmpresaFiles] = useForm({
            file_iso14001: '',
            file_emacs: '',
        })

    const inputISO = useRef(null)
    const inputEmacs = useRef(null)

    useEffect(() => {

        if (formOrganizacion.id) {

            handleFormChangeRegisterEmpresaFiles({
                file_iso14001: formOrganizacion.sgm_iso14001 ? true : '',
                file_emacs: formOrganizacion.sgm_emacs ? true : '',
            })
        }

    }, [formOrganizacion.sgm_iso14001, formOrganizacion.sgm_emacs])


    // Checks para adjuntar documentacio nde acreditacion

    const [valuesValidISO, handleVerifiedValuesISO, handleResetValuesISO] = useValidator({
        file_iso14001: { isOk: true, msgError: [`Es necesario adjuntar el documento acreditativo`] },
    })

    const [valuesValidEmacs, handleVerifiedValuesEmacs, handleResetValuesEmacs] = useValidator({
        file_emacs: { isOk: true, msgError: [`Es necesario adjuntar el documento acreditativo`] },
    })

    const handleChangeISO = (e) => {

        handleFormChangeRegisterEmpresaFiles({
            ...formOrganizacionFiles,
            file_iso14001: e.target.checked ? true : ''
        })
        handleFormChangeOrganizacion({
            ...formOrganizacion,
            sgm_iso14001: '',
        })
        // setshowInputFileISO(e.target.checked ? true : false)
    }

    const handleChangeEMACS = (e) => {
        handleFormChangeRegisterEmpresaFiles({
            ...formOrganizacionFiles,
            file_emacs: e.target.checked ? true : ''
        })
        handleFormChangeOrganizacion({
            ...formOrganizacion,
            sgm_emacs: '',
        })
        // setshowInputFileEMACS(e.target.checked ? true : false)
    }


    // Descargar pdf

    const [fetchDocumento, fetchDocumentoData, resetfetchDocumentoData] = useAxios('get', `/organizaciones`, '', 'blob')

    const handleFetchDocumento = (name, type) => {
        // const arrayLang = ['es', 'en']

        // console.log(arrayLang[idiomaDAPcon])
        fetchDocumento({
            id: formOrganizacion.id + '/obtener-documento',
            params: {
                token_doc: formOrganizacion[name],
                doc_type: type,
            }
        })
    }

    const handleEliminarDocumento = (name) => {
        handleFormChangeOrganizacion({
            ...formOrganizacion,
            [name]: '',
        })
    }

    const [pdf, setpdf] = useState('')

    useEffect(() => {

        if (fetchDocumentoData.data) {
            getDataFile(fetchDocumentoData, resetfetchDocumentoData)
        }
    }, [fetchDocumentoData])


    const handleSubmitDocuments = (e) => {

        e.preventDefault()

        let formDataISO = new FormData();

        formDataISO.append("documento", inputISO.current?.files?.[0] || '')
        formDataISO.append("org_cif", formOrganizacion.cif)
        formDataISO.append("type", 'iso14001')

        if (!formOrganizacionFiles.file_iso14001) {
            formDataISO.delete("documento")
            formDataISO.append("documento", '')
        }

        let formDataEMACS = new FormData();

        formDataEMACS.append("documento", inputEmacs.current?.files?.[0] || '')
        formDataEMACS.append("org_cif", formOrganizacion.cif)
        formDataEMACS.append("type", 'emacs')

        if (!formOrganizacionFiles.file_emacs) {
            formDataEMACS.delete("documento")
            formDataEMACS.append("documento", '')
        }

        if (formOrganizacion.sgm_iso14001.length === 0) {

            fetchRegistroEmpresaFileISO({ body: formDataISO })
        }

        if (formOrganizacion.sgm_emacs.length === 0) {

            fetchRegistroEmpresaFileEMACS({ body: formDataEMACS })
        }

    }

    useEffect(() => {

        if (fetchRegistroEmpresaFileISOData?.data?.status === 201 ||
            fetchRegistroEmpresaFileEMACSData?.data?.status === 201) {
            setShowSuccessEdit(true)
            handleResetOrganizacion()
            resetFetchOrganizacion()
            handleResetRegisterEmpresaFiles()
            fetchOrganizacion({
                params: {
                    org_cif: user.user.usuario
                }
            })
        }

    }, [fetchRegistroEmpresaFileISOData, fetchRegistroEmpresaFileEMACSData])

    const [activeClick, setActiveClick] = useState()


    // funcionalidad de pais

    const [fetchPaises, fetchPaisesData] = GetPaisJSON()

    useEffect(() => {
        fetchPaises()
    }, [])


    const [activeEspana, setactiveEspana] = useState(false)

    useEffect(() => {

        if (formOrganizacion.pais === '1' || formOrganizacion.pais === 1) {

            setactiveEspana(true)
            fetchPoblaciones({
                params: {
                    cp: formOrganizacion.codigo_postal
                }
            })
        } else {
            setactiveEspana(false)
            handleFormChangeOrganizacion({
                ...formOrganizacion,
            })
        }

    }, [formOrganizacion.pais])


    return (
        <>

            <div className="container-inner row justify-content-between">
                <Breadcrumb className="Breadcrumb-inner">
                    <Breadcrumb.Item href="#">
                        <FontAwesomeIcon icon={faHome} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Mis datos
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="d-flex justify-content-center">

                    <div className="col-12 col-xl-9">

                        <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                            {
                                fetchOrganizacionData.loading ?
                                    <div className="py-5">
                                        <Loader />
                                    </div> : <>
                                        <div className="row pt-3 px-3 w-100">
                                            <div className="col-12">
                                                <h5 className='mb-3 mt-2'>Datos de la empresa</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-3" controlId="exampleForm.ControlInput2">
                                                <Form.Label className='fw-500'>CIF:</Form.Label>
                                                <p className='mt-0 mb-0'>{formOrganizacion.cif}</p>
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-9">
                                                <InputText
                                                    label='Razón social *'
                                                    className=''
                                                    name='nombre'
                                                    value={formOrganizacion.nombre}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}

                                                />
                                            </Form.Group>

                                            <div className="col-12"></div>

                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-8">

                                                        <div className="row">
                                                            <Form.Group className="mb-3 col-lg-12">
                                                                <InputText
                                                                    label='Dirección *'
                                                                    className=''
                                                                    name='direccion'
                                                                    value={formOrganizacion.direccion}
                                                                    onChange={handleInputChangeOrganizacion}
                                                                    validation={valuesValidRegistroEmpresa}
                                                                    fetchData={fetchSendOrganizacionData}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3 col-sm-6 col-lg-6">
                                                                <InputSelect
                                                                    label='País *'
                                                                    className=''
                                                                    name='pais'
                                                                    // values={[]}
                                                                    values={fetchPaisesData.data || []}
                                                                    value={formOrganizacion.pais}
                                                                    onChange={handleInputChangeOrganizacion}
                                                                    validation={valuesValidRegistroEmpresa}
                                                                    fetchData={fetchSendOrganizacionData}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                                                <InputText
                                                                    label='Código Postal *'
                                                                    className=''
                                                                    name='codigo_postal'
                                                                    value={formOrganizacion.codigo_postal}
                                                                    onChange={handleInputChangeOrganizacion}
                                                                    validation={valuesValidRegistroEmpresa}
                                                                    fetchData={fetchSendOrganizacionData}
                                                                />
                                                            </Form.Group>

                                                            {
                                                                activeEspana ? <>
                                                                    <Form.Group className="mb-3 col-sm-6 col-lg-8">
                                                                        <InputSelect
                                                                            label='Población *'
                                                                            className=''
                                                                            name='poblacion'
                                                                            // values={[]}
                                                                            values={poblaciones}
                                                                            value={formOrganizacion.poblacion}
                                                                            onChange={handleInputChangeOrganizacion}
                                                                            validation={valuesValidRegistroEmpresa}
                                                                            fetchData={fetchSendOrganizacionData}
                                                                            disabled={!fetchPoblacionesData.data || formOrganizacion.codigo_postal?.length !== 5}
                                                                        />

                                                                    </Form.Group>
                                                                </> : <>
                                                                    <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                                        <InputText
                                                                            label='Región *'
                                                                            className=''
                                                                            name='poblacion'
                                                                            value={formOrganizacion.poblacion}
                                                                            onChange={handleInputChangeOrganizacion}
                                                                            validation={valuesValidRegistroEmpresa}
                                                                            fetchData={fetchSendOrganizacionData}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                                        <InputText
                                                                            label='Ciudad *'
                                                                            className=''
                                                                            name='provincia'
                                                                            value={formOrganizacion.provincia}
                                                                            onChange={handleInputChangeOrganizacion}
                                                                            validation={valuesValidRegistroEmpresa}
                                                                            fetchData={fetchSendOrganizacionData}
                                                                        />
                                                                    </Form.Group>
                                                                </>
                                                            }

                                                            <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                                <InputText
                                                                    label='Teléfono *'
                                                                    className=''
                                                                    name='telefono'
                                                                    value={formOrganizacion.telefono}
                                                                    onChange={handleInputChangeOrganizacion}
                                                                    validation={valuesValidRegistroEmpresa}
                                                                    fetchData={fetchSendOrganizacionData}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3 col-lg-7">
                                                                <InputText
                                                                    label='Dirección electrónica *'
                                                                    className=''
                                                                    name='email'
                                                                    value={formOrganizacion.email}
                                                                    onChange={handleInputChangeOrganizacion}
                                                                    validation={valuesValidRegistroEmpresa}
                                                                    fetchData={fetchSendOrganizacionData}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3 col-lg-12">
                                                                <InputText
                                                                    label='Web'
                                                                    className=''
                                                                    name='web'
                                                                    value={formOrganizacion.web}
                                                                    onChange={handleInputChangeOrganizacion}
                                                                    fetchData={fetchSendOrganizacionData}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-sm-9 col-lg-12">
                                                                <InputImage
                                                                    label='Logotipo'
                                                                    width='100%'
                                                                    height='13rem'
                                                                    onChange={handleChangeInputImage}
                                                                    InputFiles={InputFiles}
                                                                    setInputFiles={setInputFiles}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12"></div>


                                            <div className="col-12">
                                                <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-6">
                                                <InputText
                                                    label='Persona de contacto *'
                                                    className=''
                                                    name='persona_contacto_nombre'
                                                    value={formOrganizacion.persona_contacto_nombre}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-5">
                                                <InputText
                                                    label='Cargo *'
                                                    className=''
                                                    name='persona_contacto_cargo'
                                                    value={formOrganizacion.persona_contacto_cargo}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>

                                            <div className="col-12"></div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                                <InputText
                                                    label='Teléfono *'
                                                    className=''
                                                    name='persona_contacto_telefono'
                                                    value={formOrganizacion.persona_contacto_telefono}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Dirección electrónica *'
                                                    className=''
                                                    name='persona_contacto_email'
                                                    value={formOrganizacion.persona_contacto_email}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>

                                            <div className="col-12">
                                                <h5 className='mb-3 mt-2'>Contacto para facturación</h5>
                                            </div>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-6">
                                                <InputText
                                                    label='Persona de contacto para facturación *'
                                                    className=''
                                                    name='contacto_facturacion_nombre'
                                                    value={formOrganizacion.contacto_facturacion_nombre}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                                <InputText
                                                    label='Teléfono *'
                                                    className=''
                                                    name='contacto_facturacion_telefono'
                                                    value={formOrganizacion.contacto_facturacion_telefono}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3 col-lg-6">
                                                <InputText
                                                    label='Dirección electrónica *'
                                                    className=''
                                                    name='contacto_facturacion_email'
                                                    value={formOrganizacion.contacto_facturacion_email}
                                                    onChange={handleInputChangeOrganizacion}
                                                    validation={valuesValidRegistroEmpresa}
                                                    fetchData={fetchSendOrganizacionData}
                                                />
                                            </Form.Group>

                                            {/* <div className="col-12 mt-2 text-center">
                                            <MsgInputServer fetchData={fetchRegistroEmpresaData} className='mt-4' />
                                        </div> */}

                                        </div>
                                        <div className=' pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitActualizarEmpresa} fetchDataLoading={[fetchSendOrganizacionData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>
                                    </>
                            }
                        </div>

                        <div className='MainInner shadow-sm mt-4 mb-5 animate__animated animate__fadeInUp'>

                            {
                                fetchOrganizacionData.loading ?
                                    <div className="py-5">
                                        <Loader />
                                    </div> :
                                    <>
                                        <div className="row pt-3 pb-4 px-3">
                                            <div className="col-12">
                                                <h5 className='mb-3 mt-0'>Sistemas de gestión medioambiental de la empresa</h5>
                                            </div>

                                            <div className="col-12 mt-2 pb-2" key='iso14001'>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='iso14001'
                                                    checked={formOrganizacionFiles.file_iso14001}
                                                    onChange={handleChangeISO}
                                                    id='iso14001'
                                                    label={
                                                        <>
                                                            <div>La empresa dispone de la acreditación Sistema de gestión Medioambiental ISO 14001.</div>
                                                            <div>(adjuntar documento acreditativo)</div>
                                                        </>
                                                    }
                                                />
                                                <MsgInput obj={valuesValidRegistroEmpresa} name='iso14001' />

                                            </div>
                                            {
                                                formOrganizacion.sgm_iso14001 && formOrganizacionFiles.file_iso14001 &&
                                                <div className='ps-4 d-flex align-items-center Files__container ms-2'>
                                                    <ListGroup className='w-50 d-flex align-items-center Files__container'>
                                                        <FileList
                                                            eventClick={() => {
                                                                handleFetchDocumento('sgm_iso14001', 'iso14001')
                                                                setActiveClick('iso14001')
                                                            }}
                                                            eventDelete={() => handleEliminarDocumento('sgm_iso14001')}
                                                            name='ISO 14001'
                                                            isLoading={fetchDocumentoData.loading && activeClick === 'iso14001'}
                                                        />
                                                    </ListGroup>

                                                </div>
                                            }

                                            {(!formOrganizacion.sgm_iso14001 && formOrganizacionFiles.file_iso14001) &&
                                                <Form.Group controlId="formFile" className="mb-3 ps-4 ms-1">
                                                    <InputFile
                                                        name='file_iso14001'
                                                        onChange={handleInputChangeRegisterEmpresaFiles}
                                                        referencia={inputISO}
                                                        validation={valuesValidISO}
                                                    />
                                                </Form.Group>
                                            }

                                            <div className="col-12 mt-4 pb-2" key='emacs'>
                                                <Form.Check
                                                    type='checkbox'
                                                    name='emacs'
                                                    checked={formOrganizacionFiles.file_emacs}
                                                    onChange={handleChangeEMACS}
                                                    id='emacs'

                                                    label={
                                                        <>
                                                            <div>La empresa dispone de la acreditación Sistema de gestión Medioambiental EMAS.</div>
                                                            <div>(adjuntar documento acreditativo)</div>
                                                        </>
                                                    }
                                                />
                                                <MsgInput obj={valuesValidRegistroEmpresa} name='emacs' />

                                            </div>
                                            {
                                                formOrganizacion.sgm_emacs && formOrganizacionFiles.file_emacs &&
                                                <div className='ps-4 d-flex align-items-center Files__container ms-2'>
                                                    <ListGroup className='w-50 d-flex align-items-center Files__container'>
                                                        <FileList
                                                            eventClick={() => {
                                                                handleFetchDocumento('sgm_emacs', 'emacs')
                                                                setActiveClick('emacs')
                                                            }}
                                                            eventDelete={() => handleEliminarDocumento('sgm_emacs')}
                                                            name='EMAS'
                                                            isLoading={fetchDocumentoData.loading && activeClick === 'emacs'}
                                                        />
                                                    </ListGroup>

                                                </div>
                                            }
                                            {
                                                (!formOrganizacion.sgm_emacs && formOrganizacionFiles.file_emacs) && <Form.Group controlId="formFile" className="mb-3 ps-4 ms-1">
                                                    <InputFile
                                                        name='file_emacs'
                                                        onChange={handleInputChangeRegisterEmpresaFiles}
                                                        referencia={inputEmacs}
                                                        validation={valuesValidEmacs}
                                                    />
                                                </Form.Group>
                                            }

                                        </div>

                                        <div className=' pt-3 d-flex justify-content-end border-top'>
                                            <ButtonSpinner
                                                variant="info" type="submit" className="me-2"
                                                handleSubmit={handleSubmitDocuments} fetchDataLoading={[fetchRegistroEmpresaFileISOData.loading || fetchRegistroEmpresaFileEMACSData.loading]}
                                                value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                            />
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                escalas={[
                    { x: 1, y: 1, label: 'Cuadrado' },
                    { x: 4, y: 2, label: 'Rectángulo' }
                ]}
            />
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchDocumentoData} />

        </>

    )
}
