import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Alert, Button, Form, Modal, Spinner, Table, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { Loader } from '../../../components/Loader/Loader';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputText} from '../../../components/Inputs';
import { InputRadios } from '../../../components/Inputs/InputRadios';
import InputImage from '../../../components/Inputs/InputImage';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import queryString from 'query-string';
import { PaginationT } from '../../../components/PaginationT/PaginationT';
import {BtnReload} from './BtnReload'

export const ModalDestinatarios = ({ data, handleCloseModal}) => {
    const location = useLocation()
    const history = useHistory();

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        magic: '',
        page: 1,
    });

    const [fetchDestinatarios, fetchDestinatariosData] = useAxios('GET', `/comunicaciones/admin`)

    //const [fetchPatchArchivo, fetchPatchArchivoData] = PatchArchivoJson();

    const handleReloadTable = () => {
        fetchDestinatarios({
            id: data?.id+'/destinatarios',
            params: {
                ...formValuesSearch,
            }
        })
    }

    const handleSearch = (event) => {
        event.preventDefault();

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1,
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchDestinatarios({
                id: data?.id+'/destinatarios',
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    };

    useEffect(() => {
        if(data.value){
            fetchDestinatarios({
                id: data?.id+'/destinatarios',
                params: {
                    ...formValuesSearch,
                }
            })
        }
    }, [data.value, formValuesSearch.page, formValuesSearch.orderby])

    return (
        <Modal show={data.value} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Listado de destinatarios</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
            <div className="container">
                <div className="row d-flex justify-content-end"> 
                    <div className="col-12 col-md-6 mb-4">
                        <form onSubmit={handleSearch}>
                            <InputGroup>
                              <FormControl
                                placeholder="Buscar por: asunto, email destinatario"
                                aria-label="Buscar"
                                aria-describedby="basic-addon2"
                                name="magic"
                                value={formValuesSearch.magic} 
                                onChange={handleInputChangeSearch}
                              />
                              <Button variant="primary" type="submit">Buscar</Button>
                            </InputGroup>
                          </form>
                      </div>  
                  </div>  
            </div>
           { 
              fetchDestinatariosData?.loading ?
                        <Loader /> :
                        <>
                            {
                                fetchDestinatariosData?.data?.data?.data?.total ?
                                    <>
                                        <Table hover bordered responsive className='tableMain'>
                                            <thead>
                                                <tr className="text-center">
                                                    <th className='text-nowrap'>Asunto</th>
                                                    <th className='text-nowrap'>Destinatario email</th>
                                                    <th className='text-nowrap text-center'>Fecha enviado</th>
                                                    <th className='text-nowrap text-center'>Estado</th>
                                                    <th className='text-center'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {fetchDestinatariosData?.data?.data?.data?.data?.map((item) => {
                                                    return (
                                                        <tr key={item.id} className="text-center">
                                                            <td valign='middle'>{item.asunto}</td>
                                                            <td valign='middle'>{item.destinatario_email}</td>
                                                            <td valign='middle'>{item.fecha_enviado}</td>
                                                            <td valign='middle'>{
                                                                item.error ? 
                                                                 <OverlayTrigger
                                                                    key={item.id + '1'}
                                                                    placement={'top'}
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-${item.id}`}>
                                                                           {item.error}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button variant="danger" size="sm" ><FontAwesomeIcon icon={faExclamationTriangle} className='text-white' /></Button>
                                                                </OverlayTrigger>
                                                                :
                                                                <Button variant="success" size="sm" ><FontAwesomeIcon icon={faCheckCircle} className='text-white' /></Button>

                                                            }</td>
                                                            <td valign='middle' className='text-center'>
                                                                <BtnReload
                                                                data={item.id}
                                                                key={item.id}
                                                                handleReloadTable={handleReloadTable}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                        <PaginationT
                                            total={fetchDestinatariosData?.data?.data?.data?.total}
                                            handleFormChange={handleFormChange}
                                            formValuesSearch={formValuesSearch}
                                            perPage={fetchDestinatariosData?.data?.data?.data?.per_page}
                                        />
                                    </>
                                    :
                                    <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                            }
                        </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
