import React, { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputText = ({
    label, className = '', name, type = 'text', value, onChange, rows = '', max, maxLength,
    placeholder, validation = {}, fetchData = {}, disabled, as, style, readonly, min, adapter
}) => {

    const [errorServer, setErrorServer] = useState(false)
    
    const handleOnchange = (e) => {

        if (validation[name])
            validation[name].isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        fetchData.error = null

        if (min !== undefined && Number(e.target.value) < min)
            return

        // if (max !== undefined && e.target.value?.length > max)
        //     return

        if (errorServer)
            setErrorServer(false)

        onChange(e)
    }

    return (
        <>
            {label && <Form.Label className='fw-500'>{label}</Form.Label>}
            <Form.Control
                type={type}
                as={as}
                rows={rows}
                className={`${className} ${((validation ? getMsgInputColor(validation, name) : false) || errorServer) ? 'is-invalid' : ''}`}
                name={name}
                value={value}
                onChange={handleOnchange}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete='one-time-code'
                style={style}
                maxLength={maxLength}
            />
            { validation && <MsgInput obj={validation} name={name} /> }
            <MsgInputServer fetchData={fetchData} name={name} setErrorServer={setErrorServer} errorServer={errorServer} adapter={adapter} />
        </>
    )
}
