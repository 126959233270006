import React from "react";
import { useAxios } from "../../../hooks/useAxios";
import { useEffect } from "react";
import { isStatusSucess } from "../../../helpers/helpers";
import { Button, Form, Table } from "react-bootstrap";
import { Loader } from "../../../components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ModalPagoCuota } from "./ModalPagoCuota";
import { useForm } from "../../../hooks/useForm";
import { ModalStatusServer2 } from "../../../components/ModalStatusServer/ModalStatusServer2";
import { ButtonSpinner } from "../../../components/ButtonSpinner/ButtonSpinner";
import { PaginationT } from "../../../components/PaginationT/PaginationT";
import { ModalPagoDAPcons } from "./ModalPagoDAPcons";
import './PagosDAPcons.css'
import { imagesFlags } from "../../../helpers/imagesFlags";
import { images } from "../../../helpers/images";
import { useHistory } from 'react-router-dom';
import { GetParametroJSON } from "../../../services/parametro.service";

export const PagosDAPcons = ({ id, fetchOrganizacionData }) => {

    const [fetchDAPconsOrg, fetchDAPconsOrgData, resetFetchDAPconsOrg] = useAxios('get', `/dapcons`)

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
        organizacion_id: ''
    });

    useEffect(() => {

        if (formValuesSearch.organizacion_id) {

            fetchDAPconsOrg({
                params: {
                    organizacion_id: formValuesSearch.organizacion_id,
                    page: formValuesSearch.page,
                }
            })
        }

    }, [formValuesSearch.page, formValuesSearch.organizacion_id])

    useEffect(() => {

        if (isStatusSucess(fetchOrganizacionData.status)) {

            handleFormChange({
                ...formValuesSearch,
                organizacion_id: fetchOrganizacionData.data?.id,
            })
        }
    }, [fetchOrganizacionData]);

    const [showModalPagoDAPcons, setShowModalPagoDAPcons] = useState({});

    const handleCloseModalPagoDAPcons = () => setShowModalPagoDAPcons({
        value: false,
        payload: {}
    })
    const handleShowModalPagoDAPcons = (payload = {}) => setShowModalPagoDAPcons({
        value: true,
        payload
    });


    // ------------ DELETE pago cuota

    const [idDapcons, setidDapcons] = useState('');


    // const [fetchPagoDELETE, fetchPagoDELETEData] = useAxios('DELETE', '/cuotas/borrado-pago')
    const [fetchPagarDapcons, fetchPagarDapconsData, resetFetchPagarDapcons] = useAxios('patch', `/dapcons/registro-pagos`)

    const handleSubmitDeletePago = (item) => {
        fetchPagarDapcons({
            body: {
                registro_pagos: [
                    {
                        dapcons_id: item.id,
                        pagado: ''
                    }
                ]
            }
        })
        setidDapcons(item.id)
    }

    useEffect(() => {

        if (isStatusSucess(fetchPagarDapconsData?.data?.status)) {
            fetchDAPconsOrg({
                params: {
                    organizacion_id: formValuesSearch.organizacion_id,
                    page: formValuesSearch.page,
                }
            })
        }

    }, [fetchPagarDapconsData])

    const history = useHistory();

    // Parametro ID

    const [fetchEstadosDAPcons, fetchEstadosDAPconsData] = GetParametroJSON('estados_dapcons')

    useEffect(() => {

        fetchEstadosDAPcons();

    }, [])

    // Preparando select de Estados

    // const [listEstados, setListEstados] = useState([])

    // useEffect(() => {

    //     if (fetchEstadosDAPconsData.data) {
    //         const estados_dapcons = fetchEstadosDAPconsData?.data
    //         console.log(estados_dapcons)

    //         const listTemp = estados_dapcons.map(item => {

    //             if (estaVerificando && item.id === 'borrador') {
    //                 return null
    //             }
    //             return item
    //         })
    //         setListEstados(listTemp.filter(item => item))
    //     }

    // }, [fetchEstadosDAPconsData])

    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">

                <div className="d-flex justify-content-between mb-3">
                    <h5 className='mt-0'>Pagos DAPcons</h5>
                </div>
                {
                    fetchOrganizacionData?.loading ?
                        <div className="py-5 d-flex justify-content-center">
                            <Loader />
                        </div>
                        :
                        <>
                            <Table hover bordered responsive className='tableMain TableStripeRowSpan'>
                                <thead>
                                    <tr>
                                        <th className='text-nowrap'>Codigo DAPcons</th>
                                        <th className='text-center'>Fecha creación</th>
                                        <th className='text-center'>Idioma</th>
                                        <th className='text-nowrap'>Estado</th>

                                        <th className='text-nowrap text-center'>
                                            <img src={images('./ECO_Logo.png')} className="" alt="logo" width='40px' />

                                        </th>
                                        <th className='text-nowrap text-center'>Fecha de pago</th>
                                        <th className='text-nowrap'></th>
                                        {/* <th className="shrink-td"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fetchDAPconsOrgData.loading ?
                                            <tr>
                                                <td className='text-center' colSpan={10}>
                                                    <Loader />
                                                </td>
                                            </tr>
                                            :
                                            fetchDAPconsOrgData.data?.data?.data?.data?.length > 0 ? <>
                                                {
                                                    fetchDAPconsOrgData.data?.data?.data?.data?.map((item, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    item.dapcons_valores?.map((item2, index2) => {
                                                                        return (
                                                                            <tr className={index % 2 === 0 ? 'tr_par' : ''}>
                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td rowspan={item.dapcons_valores?.length}>
                                                                                        <span className="btn-link" onClick={() => history.push({
                                                                                            pathname: `/DAPcons/${item.id}/${item['idiomas'][index2]['idioma_id']}`
                                                                                        })}>
                                                                                            {item.dapcons_codigo ? item.dapcons_codigo : `DAPcons ${item.id}`}

                                                                                        </span>
                                                                                    </td>
                                                                                }

                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td rowspan={item.dapcons_valores?.length} className="text-center">
                                                                                        {item.dapcons_fecha_creacion || ''}
                                                                                    </td>
                                                                                }

                                                                                <td valign='middle' className='text-nowrap text-center icon-flag'>
                                                                                    <img src={imagesFlags(`./${item['idiomas'][index2]['idioma_id']}.png`)} alt="" />
                                                                                </td>
                                                                                <td>
                                                                                    {fetchEstadosDAPconsData.data?.find(item2 => item2.id === item['idiomas']?.[index2]?.dapcons_idioma_estado)?.nombre}
                                                                                </td>
                                                                                {/* <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item2['dv_fecha_creacion'] ? (new Date(item2['dv_fecha_creacion'])?.toLocaleDateString()) : ''}
                                                                                </td> */}
                                                                                <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item['idiomas'][index2]['inscripcion_ecoplatform'] ? 'Si' : ''}
                                                                                </td>
                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td rowspan={item.dapcons_valores?.length} valign="middle" className="text-center">
                                                                                        {item.pagado ? (new Date(item.pagado)?.toLocaleDateString()) : ''}
                                                                                    </td>
                                                                                }

                                                                                {
                                                                                    index2 === 0 &&
                                                                                    <td className="shrink-td" rowspan={item.dapcons_valores?.length} valign="middle">
                                                                                        {item.pagado ?
                                                                                            <>
                                                                                                <ButtonSpinner
                                                                                                    variant="outline-danger" type="submit" className="ms-2" size='sm'
                                                                                                    handleSubmit={() => handleSubmitDeletePago(item)} fetchDataLoading={[idDapcons === item.id && fetchPagarDapconsData.loading]}
                                                                                                    value='Borrar Pago' icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                                                                                />
                                                                                            </> :
                                                                                            <>
                                                                                                <Button variant="primary" size="sm" className=''
                                                                                                    onClick={() => handleShowModalPagoDAPcons({
                                                                                                        action: 'CREATE',
                                                                                                        item: {
                                                                                                            ...item,
                                                                                                            dapcons_id: item.id
                                                                                                        },
                                                                                                    })}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faMoneyBill} className="me-2" /> Registrar pago
                                                                                                </Button>
                                                                                            </>
                                                                                        }
                                                                                    </td>
                                                                                }

                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </>

                                                        )
                                                    })
                                                }
                                            </> : <tr>
                                                <td className='py-5 text-center' colSpan={10}>
                                                    <h5>No hay datos a mostrar</h5>
                                                </td>
                                            </tr>
                                    }


                                </tbody>
                            </Table>
                            <PaginationT
                                total={fetchDAPconsOrgData?.data?.data?.data?.total}
                                handleFormChange={handleFormChange}
                                formValuesSearch={formValuesSearch}
                                perPage={fetchDAPconsOrgData?.data?.data?.data?.per_page}
                            />

                        </>
                }

            </div>

            {/* <ModalPagoCuota
                showModalPagoCuota={showModalPagoCuota}
                handleCloseModalPagoCuota={handleCloseModalPagoCuota}
                fetchSuccess={() => fetchCuotasPendientes({
                    id: fetchOrganizacionData.data?.id + '/cuotas-pendientes'
                })}
            /> */}


            <ModalPagoDAPcons
                showModalPagoDAPcons={showModalPagoDAPcons}
                handleCloseModalPagoDAPcons={handleCloseModalPagoDAPcons}
                fetchSuccess={() => fetchDAPconsOrg({
                    params: {
                        organizacion_id: formValuesSearch.organizacion_id,
                        page: formValuesSearch.page,
                    }
                })}
            // formValuesSearch={formValuesSearch}
            />

        </>
    );
};
