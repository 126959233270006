import { faSearch, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { InputSelect, InputText } from '../../../components/Inputs'
import { getFormatDiaMesAnio, isConsultor, isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'
import { ModalTableOrganizacion } from './ModalTableOrganizacion'

export const DAPconsFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData,
    fetchEstadosDAPconsData, idiomas, estaVerificando }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    // useEffect(() => {

    //     console.log(fetchEstadosDAPconsData)
    // }, [fetchEstadosDAPconsData])

    // Preparando select de Estados

    const [listEstados, setListEstados] = useState([])

    useEffect(() => {

        if (fetchEstadosDAPconsData.data) {
            const estados_dapcons = fetchEstadosDAPconsData?.data
            console.log(estados_dapcons)

            const listTemp = estados_dapcons.map(item => {

                if (estaVerificando && item.id === 'borrador') {
                    return null
                }
                return item
            })
            setListEstados(listTemp.filter(item => item))
        }

    }, [fetchEstadosDAPconsData])

    // 

    const [organizacionSelect, setOrganizacionSelect] = useState({
        id: '',
        nombre: ''
    })

    // Modal

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        handleInputChangeSearch({
            target: {
                name: 'organizacion_id',
                value: organizacionSelect.id
            }
        })
    }, [organizacionSelect])


    return (
        <div className='col-xl-3'>
            {/* sticky-top */}
            <div className='Column-small shadow-sm '>

                <h5>{Lang('FILTRO')}</h5>

                <Form className='row' onSubmit={handleSearch}>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='Núm DAPcons:'
                            name='search'
                            value={formValuesSearch.search}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    {
                        isModuleAdministrador(modulo_app) && <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                            <Form.Label className='fw-500'>Empresa:</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={organizacionSelect.nombre}
                                    onChange={() => setOrganizacionSelect({ id: '', nombre: '' })}
                                />
                                <InputGroup.Text className='btn btn-sm btn-primary d-flex align-items-center' onClick={handleShow}>
                                    <FontAwesomeIcon icon={faWarehouse} className='' />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    }


                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <InputSelect
                            label='Estado:'
                            name='estado_id'
                            values={listEstados}
                            value={formValuesSearch.estado_id}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <InputSelect
                            label='Idioma:'
                            name='idioma_id'
                            values={idiomas}
                            value={formValuesSearch.idioma_id}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <InputSelect
                            label='Pagados:'
                            name='pagado'
                            values={[
                                {
                                    id: true, nombre: 'Pagados'
                                },
                                {
                                    id: false, nombre: 'No pagados'
                                },
                            ]}
                            value={formValuesSearch.pagado}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <div className="col-12 col-sm-12"></div>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='Desde:'
                            type='date'
                            className=''
                            name='desde'
                            value={formValuesSearch.desde}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='Hasta:'
                            type='date'
                            className=''
                            name='hasta'
                            value={formValuesSearch.hasta}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                        </button>
                    </div>
                </Form>
            </div>
            {
                isModuleAdministrador(modulo_app) &&
                <ModalTableOrganizacion show={show} handleClose={handleClose} setOrganizacionSelect={setOrganizacionSelect} />
            }

        </div>
    )
}
