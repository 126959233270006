import React, { useEffect, useState } from 'react'
import { NavStartInner } from '../NavStartInner/NavStartInner'
import { NavtopInner } from '../NavtopInner/NavtopInner'

export const LayoutNavs = () => {

    const prueba = localStorage.getItem( process.env.REACT_APP_MAIN_VARIABLE + '_ACTIVE_MENU' )
    console.log('logalstorage close: ', prueba)
    
    const [close, setClose] = useState(prueba === 'true' ? true : false)
    
    useEffect(() => {
        
        console.log('close: ', close)
        localStorage.setItem( process.env.REACT_APP_MAIN_VARIABLE + '_ACTIVE_MENU', close );
        console.log('close: ', close)

    }, [close])

    return (
        <>
            <NavtopInner setClose={setClose} close={close}/>
            <NavStartInner setClose={setClose} close={close}/>
            {/* <div className={close ? 'toggle-fade-menu' : ''} onClick={() => setClose(false)} id='fade-menu'></div> */}
        </>
    )
}
