import React from 'react'
import { Form, ListGroup } from 'react-bootstrap'

const IdiomasEcoplatform = ({ listIdiomasECO, handleChangeCheckIdioma, handleChangeCheckECOplatform}) => {
  return (
    <ListGroup>
        {
            listIdiomasECO.map((item, index) => (
                <ListGroup.Item key={item.id} className='d-flex flex-wrap'>
                    <Form.Check
                        type='checkbox'
                        name='emacs'
                        // value={formRegisterEmpresaFiles.file_emacs}
                        onChange={(e) => handleChangeCheckIdioma(e, index)}
                        id={'idioma' + item.id}

                        label={item.nombre_idioma}
                        defaultChecked={item.idioma}
                        checked={item.idioma}
                        disabled={item.id === 1}
                        style={{'width': '6rem'}}
                    />
                    {
                        item.idioma && <Form.Check
                            type='checkbox'
                            name='emacs'
                            // value={formRegisterEmpresaFiles.file_emacs}
                            onChange={(e) => handleChangeCheckECOplatform(e, index)}
                            id={'ecoplarform' + item.id}
                            checked={item.ecoplatform}
                            className='ms-3'
                            label='Inscripción a ECOplatform'
                        />
                    }
                    
                </ListGroup.Item>
            ))
        }
    </ListGroup>
  )
}

export default IdiomasEcoplatform