import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';

// import './ProcessosMain.css'

export const ModalCrearDAPcons = ({ showModalCrearDAPcons, setshowModalCrearDAPcons, handleCloseModalCrearDAPcons, fetchRCPData,
    fetchVerificadoresData, fetchIdiomasData, fetchCrearDAPcons, fetchCrearDAPconsData }) => {

    const history = useHistory();

    const [formCrearDAPcons, handleInputChangeCrearDAPcons, handleFormChangeCrearDAPcons, handleResetCrearDAPcons] = useForm({
        dapcons_rcp_id: '',
        dv_nombre_producto: '',
        verificador_nif: '',
        dapcons_confrm_veracidad_datos: '',
        idiomas: [],
        justificacion_rcp: '',
    })

    const [valuesValidCrearDAPcons, handleVerifiedValuesCrearDAPcons, handleResetValuesCrearDAPcons] = useValidator({
        dv_nombre_producto: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        dapcons_rcp_id: { isOk: true, msgError: [`El RCP es obligatorio.`] },
        verificador_nif: { isOk: true, msgError: [`El verificador es obligatorio.`] },
        dapcons_confrm_veracidad_datos: { isOk: true, msgError: [`Ha de aceptar la veracidad de los datos`] },
    })

    const [valuesValidJustificacion, handleVerifiedValuesJustificacion, handleResetValuesJustificacion] = useValidator({
        justificacion_rcp: { isOk: true, msgError: [`La justificación es obligatoria.`] },
    })

    // Preparando select de RCP

    const [listRCP, setListRCP] = useState([])

    useEffect(() => {

        // console.log('fetchRCPData', fetchRCPData);

        if (fetchRCPData.data?.status === 200) {
            const listTemp = fetchRCPData?.data?.data?.data?.map(item => {

                const item2 = item.rcp_idiomas?.find(item2 => item2.idioma_id === 1) || {}

                return {
                    id: Number(item.rcp_id),
                    nombre: `${item.rcp_equivalencia} ${item2.rcp_nombre || ''}`
                }
            })
            setListRCP(listTemp)
        }

    }, [fetchRCPData])

    // Preparando select de Verificadores

    const [listVerificadores, setListVerificadores] = useState([])

    useEffect(() => {

        if (fetchVerificadoresData.data?.status === 200) {
            const listTemp = fetchVerificadoresData?.data?.data?.data?.map(item => {
                return {
                    id: item.consultor_nif,
                    nombre: `${item.consultor_nombre} ${item.consultor_apellidos}`
                }
            })
            setListVerificadores(listTemp)
        }

    }, [fetchVerificadoresData])

    // Preparando select de Idiomas

    const [listIdiomasECO, setListIdiomasECO] = useState([])

    useEffect(() => {

        if (fetchIdiomasData.data?.status === 200) {
            const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                return {
                    id: item.id,
                    nombre: `${item.idioma_nombre}`
                }
            })

            const initialIdiomasECO = listTemp.map(item => {
                return ({
                    id: item.id,
                    idioma: item.id === 1 ? true : false,
                    nombre_idioma: item.nombre,
                    ecoplatform: false,
                })
            })

            setListIdiomasECO(initialIdiomasECO)
        }

    }, [fetchIdiomasData])

    const handleChangeCheckIdioma = (e, index) => {

        const listIdiomasECOTemp = [...listIdiomasECO]

        listIdiomasECOTemp[index]['idioma'] = e.target.checked ? true : false

        if (!e.target.checked) {
            listIdiomasECOTemp[index]['ecoplatform'] = false
        }

        setListIdiomasECO(listIdiomasECOTemp)
    }

    const handleChangeCheckECOplatform = (e, index) => {
        const listIdiomasECOTemp = [...listIdiomasECO]

        listIdiomasECOTemp[index]['ecoplatform'] = e.target.checked ? true : false

        setListIdiomasECO(listIdiomasECOTemp)
    }

    // Actualizar cada vez que se modifique los checks (verificando y poniendolo al form general)

    useEffect(() => {

        let idiomasFinal = listIdiomasECO.filter(item => item.idioma === true)

        idiomasFinal = idiomasFinal.map(item => {
            return {
                id: String(item.id),
                ecoplatform: item.ecoplatform
            }
        })

        // console.log(idiomasFinal)
        handleFormChangeCrearDAPcons({
            ...formCrearDAPcons,
            idiomas: idiomasFinal
        })

    }, [listIdiomasECO])


    const handleChangeVeracidad = (e) => {

        handleFormChangeCrearDAPcons({
            ...formCrearDAPcons,
            dapcons_confrm_veracidad_datos: e.target.checked ? true : ''
        })
    }

    const handleSubmitAltaDAPcons = () => {

        if (showJustificacion ?
            handleVerifiedValuesJustificacion(formCrearDAPcons) && handleVerifiedValuesCrearDAPcons(formCrearDAPcons) :
            handleVerifiedValuesCrearDAPcons(formCrearDAPcons)) {
            setconsultor404(0)
            fetchCrearDAPcons({
                body: {
                    ...formCrearDAPcons,
                    nif: formConsultor.nif
                }
            })
        }

        // if ((showJustificacion && handleVerifiedValuesJustificacion(formCrearDAPcons)) 
        //     || handleVerifiedValuesCrearDAPcons(formCrearDAPcons)) {

        // }
    }


    // Buscar Consultor por NIF

    const [formConsultor, handleInputChangeConsultor, handleFormChangeConsultor, handleResetConsultor] = useForm({
        nif: '',
    })

    const [valuesValidConsultor, handleVerifiedValuesConsultor, handleResetValuesConsultor] = useValidator({
        nif: { isOk: true, msgError: [`El nif es obligatorio.`] },
    })

    const [consultor404, setconsultor404] = useState(0)

    const [fetchConsultor, fetchConsultorData] = useAxios('get', `/consultores`)

    const handleClickSearchConsultor = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesConsultor(formConsultor)) {
            setconsultor404(0)
            fetchConsultor({
                id: formConsultor.nif + '/nif'
            })
        }
    }

    useEffect(() => {

        // console.log(fetchConsultorData)
        if (fetchConsultorData.error?.response?.status === 404) {
            setconsultor404(404)
        } else if (fetchConsultorData.data?.status === 200) {
            setconsultor404(200)
        }
    }, [fetchConsultorData])

    // useState para mostrar la justificacion

    const [showJustificacion, setshowJustificacion] = useState(false);

    // Middleware onChange

    function handleOnChangeRCP(e) {

        const idRCP = e.target.value

        handleFormChangeCrearDAPcons({
            ...formCrearDAPcons,
            dapcons_rcp_id: idRCP
        })

        const isRCP100 = fetchRCPData?.data?.data?.data?.find((item) => item.rcp_id === +idRCP)?.codigo_para_codificacion_dapcons === '100'

        setshowJustificacion(isRCP100)
    }

    // useEffect(() => {
    //   console.log('formCrearDAPcons', formCrearDAPcons);
    // }, [formCrearDAPcons]);

    useEffect(() => {
        if (showJustificacion && !formCrearDAPcons?.justificacion_rcp) {
            handleInputChangeCrearDAPcons({
                target: {
                    name: 'justificacion_rcp',
                    value: 'No se ha encontrado ninguna RCP especifica de productos similares. Se quiere desarrollar una DAPcons de un producto para el que no existe una normativa internacional de RCP, ni una RCP en el Programa DAPconstrucción, ni una RCP en otro programa internacional'
                }
            })
        }
    }, [showJustificacion]);


    return (
        <Modal show={showModalCrearDAPcons} onHide={handleCloseModalCrearDAPcons} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Ficha de creación del DAPcons</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>

                <Form className='row'>

                    <Form.Group className="mb-3 col-sm-12 col-lg-8">
                        <InputSelect
                            label='RCP de referencia: *'
                            className=''
                            name='dapcons_rcp_id'
                            values={listRCP}
                            // values={poblaciones}
                            value={formCrearDAPcons.dapcons_rcp_id}
                            onChange={handleOnChangeRCP}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearDAPconsData}
                        // disabled={!fetchPoblacionesData.data || formCrearDAPcons.codigo_postal?.length !== 5}
                        />
                    </Form.Group>

                    <div className="col-12"></div>

                    {
                        showJustificacion &&
                        <Form.Group className="col-12 mb-4">
                            <Form.Label>Justificación del RCP 100: *</Form.Label>
                            <InputText
                                className=''
                                as="textarea"
                                rows={3}
                                name='justificacion_rcp'
                                onChange={handleInputChangeCrearDAPcons}
                                value={formCrearDAPcons.justificacion_rcp}
                                validation={valuesValidJustificacion}
                            />
                        </Form.Group>
                    }

                    <Form.Group className="mb-3 col-sm-12 col-lg-12">
                        <InputText
                            label='Nombre del producto: *'
                            className=''
                            name='dv_nombre_producto'
                            value={formCrearDAPcons.dv_nombre_producto}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearDAPconsData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-sm-8 col-lg-4">
                        <div className="d-flex align-items-start">
                            <div className='flex-shrink-sm-0 flex-grow-1'>
                                <InputText
                                    label='NIF del Consultor ACV: *'
                                    className=''
                                    name='nif'
                                    value={formConsultor.nif}
                                    onChange={handleInputChangeConsultor}
                                    validation={valuesValidConsultor}
                                    fetchData={fetchConsultorData}
                                />

                                <MsgInputServer fetchData={fetchCrearDAPconsData} className='mt-2' name='nif' />
                            </div>
                            <ButtonSpinner
                                variant="outline-primary" type="submit" className="ms-2 d-flex align-items-center justify-content-center align-input-label"
                                handleSubmit={handleClickSearchConsultor} fetchDataLoading={[fetchConsultorData.loading]}
                                value='' icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                            />
                        </div>
                        {
                            consultor404 === 404 && <Form.Text className='text-danger d-block'>
                                Es necesario que el Consultor ACV este registrado para poder dar de alta el DAPcon
                            </Form.Text>
                        }
                        {
                            consultor404 === 200 && <Form.Text className='text-success d-block'>
                                El Consultor ACV es válido
                            </Form.Text>
                        }

                        <MsgInputServer fetchData={fetchCrearDAPconsData} className='mt-2' />

                    </Form.Group>

                    <Form.Group className="mb-3 col-sm-12 ms-lg-4 col-lg-4">
                        <InputSelect
                            label='Verificador: *'
                            className=''
                            name='verificador_nif'
                            values={listVerificadores}
                            // values={poblaciones}
                            value={formCrearDAPcons.verificador_nif}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearDAPconsData}
                        // disabled={!fetchPoblacionesData.data || formCrearDAPcons.codigo_postal?.length !== 5}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-sm-12 ms-lg-7 col-lg-8">
                        <label className="form-label">Idiomas de publicación:</label>
                        <IdiomasEcoplatform
                            listIdiomasECO={listIdiomasECO}
                            handleChangeCheckIdioma={handleChangeCheckIdioma}
                            handleChangeCheckECOplatform={handleChangeCheckECOplatform}
                        />
                    </Form.Group>


                    <div className="col-12">
                        <Form.Check
                            type='checkbox'
                            name='dapcons_confrm_veracidad_datos'
                            value={formCrearDAPcons.dapcons_confrm_veracidad_datos}
                            onChange={handleChangeVeracidad}
                            id={`default-checkbox`}
                            label='La empresa confirma la veracidad de los datos que comunica en la presente solicitud'
                        />
                        <MsgInput obj={valuesValidCrearDAPcons} name='dapcons_confrm_veracidad_datos' />

                    </div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" >
                    Salir
                </Button>
                <ButtonSpinner
                    variant="info" type="submit" className="ms-2"
                    handleSubmit={handleSubmitAltaDAPcons} fetchDataLoading={[fetchCrearDAPconsData.loading]}
                    value='Crear DAPcons' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                />
            </Modal.Footer>
        </Modal>
    )
}
