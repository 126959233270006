import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { faBars, faCheck, faCheckCircle, faCheckDouble, faExternalLinkAlt, faExternalLinkSquareAlt, faGlobe, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Breadcrumb, Button, DropdownButton, Dropdown, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { useAxios } from '../../../../hooks/useAxios';
import { Loader } from '../../../../components/Loader/Loader';
import { ModalStatus } from '../../../../components/ModalStatusServer/ModalStatus';
import { DAPconSeccionSeguimiento } from '../DAPconSeccionSeguimiento';
import { DAPconAdminSeccion0 } from './DAPconAdminSeccion0';
import DAPconLinkMenu from '../DAPconLinkMenu';
import { useLocation } from 'react-router-dom';
import { ModalStatusError } from '../../../../components/ModalStatusServer/ModalStatusError';
import { useForm } from '../../../../hooks/useForm';
import { DropdownLanguage } from '../../../../components/DropdownLanguage/DropdownLanguage';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { GetParametroJSON } from '../../../../services/parametro.service';
import { isAdmin, isModuleAdministrador } from '../../../../helpers/helpers';
import { useContext } from 'react';
import { AuthContext } from '../../../../auth/AuthContext';
import { ModalCambiarEstado } from './ModalCambiarEstado';
import { ModalModificarIdiomas } from './ModalModificarIdiomas';

export const DAPconAdmin = ({ match, namePage, nameDAPcon, setVistaConsultorAdmin }) => {

    const location = useLocation()

    const { user } = useContext(AuthContext);

    const [fetchFichaResumen, fetchFichaResumenData] = useAxios('get', `/dapcons/${match.params?.id}/ficha-resumen`)

    const [idiomaDAPcon, setIdiomaDAPcon] = useState(Number(match.params?.idioma_id))

    useEffect(() => {
        fetchFichaResumen({
            params: {
                idioma_id: idiomaDAPcon
            }
        })
    }, [idiomaDAPcon])

    const [formDAPcon, handleInputChangeDAPcon, handleFormChangeDAPcon, handleResetDAPcon] = useForm({
        consultor: {},
        doc_inf_verificacion_dapcons: [],
        doc_opcionales_no_verificables: [],
        doc_opcionales_verificables: [],
        documento_ficha_producto: '',
        documento_inf_analisis_ciclo_vida: '',
        dv_descripcion_producto: '',
        dv_nombre_producto: '',
        organizacion: {},
        rcp: {},
        verificador: {},
        nif: '',

        dapcons_codigo: '',
        all_idiomas: [],
        dapcons_estado_param_id: '',
        dapcons_idioma_estado: '',
        bloqueado: false,
        fecha_publicacion_en_caateeb: '',
        fecha_publicacion_en_ecoplatform: '',
        consultor_nombresapellidos: '',

        // Existentes
        org_nombre: '',
        dapcons_rcp_id: '',
        verificador_nif: '',
        estadoDAPcons: '',
        pagado: '',
    })


    useEffect(() => {
        console.log(fetchFichaResumenData)
        if (fetchFichaResumenData.data) {

            const formDAPconTemp = fetchFichaResumenData.data?.data?.data

            if (!formDAPconTemp) {
                history.push('/DAPcons')
            } else {

                handleFormChangeDAPcon({
                    ...formDAPconTemp,
                    org_nombre: formDAPconTemp.organizacion.org_nombre,
                    dapcons_rcp_id: formDAPconTemp.rcp.rcp_id,
                    verificador_nif: formDAPconTemp.verificador.consultor_nif,
                    estadoDAPcons: formDAPconTemp.idiomas?.[0]?.dapcons_idioma_estado,
                    nif: formDAPconTemp.consultor?.consultor_nif,
                    isECOplatform: formDAPconTemp.idiomas?.[0]?.inscripcion_ecoplatform,
                    fecha_informe_verificacion_producto: formDAPconTemp.fecha_informe_verificacion_producto?.split(' ')?.[0],
                    fecha_publicacion_en_caateeb: formDAPconTemp.idiomas?.[0]?.fecha_publicacion_en_caateeb,
                    fecha_publicacion_en_ecoplatform: formDAPconTemp.idiomas?.[0]?.fecha_publicacion_en_ecoplatform,
                    justificacion_rcp: formDAPconTemp.justificacion_rcp,
                    fecha_verificacion_rcp: formDAPconTemp.fecha_verificacion_rcp,
                    pagado: formDAPconTemp.pagado,
                })

                setConsultorSelect({
                    id: formDAPconTemp.consultor?.consultor_nif,
                    nombre: (formDAPconTemp.consultor?.consultor_nombre || '') + ' ' + (formDAPconTemp.consultor?.consultor_apellidos || '')
                })
            }

        }
        if (fetchFichaResumenData.error) {
            history.push('/DAPcons')
        }
    }, [fetchFichaResumenData])

    // --------- Variable de consultores

    const [consultorSelect, setConsultorSelect] = useState({
        id: '',
        nombre: ''
    })
    // --------- End Variable de consultores

    const history = useHistory();

    const [hideNav, setHideNav] = useState(false);
    const [activeNavDAPcon, setActiveNavDAPcon] = useState(0);

    useEffect(() => {
        // console.log(hideNav)
        const timer = setTimeout(() => {

            // setPointerEvents(true)
        }, 500);

        return () => clearTimeout(timer);

    }, [hideNav])

    const box = useRef(0);

    const [series, setSeries] = useState([
        ''
    ])

    const handleChangeSerie = (e, index) => {
        const seriesTemp = [...series]
        seriesTemp[index] = e.target.value
        setSeries(seriesTemp)
    }

    const [showModalCrearIncidencia, setShowModalCrearIncidencia] = useState(false);

    const handleCloseModalCrearIncidencia = () => setShowModalCrearIncidencia(false)
    const handleShowModalCrearIncidencia = () => setShowModalCrearIncidencia(true);

    const [showSuccessSoli, setShowSuccessSoli] = useState(false);

    const [fetchPublicarECOplatform, fetchPublicarECOplatformData] = useAxios('post', `/dapcons/${match.params?.id}/ecoplatform/publish`)


    const handleClickPublicarECOplatform = (e) => {

        e.preventDefault()
        // const arrayLang = ['es', 'en']

        fetchPublicarECOplatform({
            id: idiomaDAPcon
        })

    }

    // useEffect(() => {
    //     console.log(fetchPublicarECOplatformData)
    //     if (fetchPublicarECOplatformData.data) {

    //         const formDAPconTemp = fetchPublicarECOplatformData.data?.data?.data[0]

    //     }
    // }, [fetchPublicarECOplatformData])

    useEffect(() => {
        console.log(fetchPublicarECOplatformData.data)
        if (fetchPublicarECOplatformData.data?.status === 200) {
            setShowSuccessSoli(true)
            fetchFichaResumen({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchPublicarECOplatformData])

    // Publicar caateeb

    const [fetchPublicarCaateeb, fetchPublicarCaateebData] = useAxios('post', `/dapcons/${match.params?.id}/caateeb/publish`)

    const handleClickPublicarCaateeb = (e) => {

        e.preventDefault()

        fetchPublicarCaateeb({
            id: idiomaDAPcon
        })
    }

    useEffect(() => {
        console.log(fetchPublicarCaateebData.data)
        if (fetchPublicarCaateebData.data?.status === 200) {
            setShowSuccessSoli(true)
            fetchFichaResumen({
                params: {
                    idioma_id: idiomaDAPcon
                }
            })
        }

    }, [fetchPublicarCaateebData])


    // Parametro ID

    const [fetchEstadosDAPcons, fetchEstadosDAPconsData] = GetParametroJSON('estados_dapcons')

    useEffect(() => {

        fetchEstadosDAPcons();

    }, [])

    // -------- Modal Cambiar estado dapcons

    const [showModalCambiarEstado, setShowModalCambiarEstado] = useState({
        value: false,
        item: null
    });

    const handleCloseModalCambiarEstado = () => setShowModalCambiarEstado({
        value: false,
        item: null
    })
    const handleShowModalCambiarEstado = (item) => setShowModalCambiarEstado({
        value: true,
        item
    });

    // -------- Modal Modificar idiomas

    const [showModal__ModificarIdiomas, setShowModal__ModificarIdiomas] = useState({
        value: false,
        item: null
    });

    const handleCloseModal__ModificarIdiomas = () => setShowModal__ModificarIdiomas({
        value: false,
        item: null
    })
    const handleShowModal__ModificarIdiomas = (item) => setShowModal__ModificarIdiomas({
        value: true,
        item
    });


    return (
        <>
            {
                fetchFichaResumenData.loading || !fetchFichaResumenData.data?.data?.data || fetchEstadosDAPconsData.loading ?
                    <div className="py-5">
                        <Loader />
                    </div> : <>
                        <div className='d-flex justify-content-between align-items-center mb-3 Nav-top-DAPcon'>
                            <div className='d-flex'>
                                <Breadcrumb className="Breadcrumb-inner Breadcrumb-DAPcon">
                                    <Breadcrumb.Item href="#">
                                        <FontAwesomeIcon icon={faHome} />
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => history.push('/DAPcons')}>
                                        Dapcons
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active className='fw-500'>
                                        {formDAPcon.dapcons_codigo ? formDAPcon.dapcons_codigo : 'DAPcons ' + match.params?.id}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <div className='ms-3'>

                                    {
                                        formDAPcon?.dapcons_estado_param_id === 'pendiente_verificar_rcp' ?
                                            <Badge pill bg="danger" className='fw-500'>
                                                {fetchEstadosDAPconsData?.data?.find(item => item.id === 'pendiente_verificar_rcp')?.nombre}
                                            </Badge>
                                            :
                                            <Badge pill bg="secondary" className='fw-500'>
                                                {fetchEstadosDAPconsData?.data?.find(item => item.id === formDAPcon?.estadoDAPcons)?.nombre}
                                            </Badge>
                                    }
                                </div>
                            </div>


                        </div>

                        <div className={`col-12 ${hideNav ? 'HideNav' : ''}`} >
                            <div className="row">

                                <div className='col-xl-3' ref={box} id='dapcon-col-start'>
                                    <div className='Column-small shadow-sm  p-0' id='listgroup-dapcon'>

                                        {/* <h5 className='ps-3 pt-3'>Formulario</h5> */}

                                        <ListGroup variant="flush" className='mt-0'>

                                            <p className={`m-0 listgroup-subtitle py-1 mb-1 d-flex ${hideNav ? 'justify-content-center' : 'justify-content-between'}`}>
                                                <small className=''>
                                                    {
                                                        !hideNav && <span>Datos generales</span>
                                                    }
                                                </small>
                                                <FontAwesomeIcon icon={faBars} className='cursor-pointer' onClick={() => setHideNav(!hideNav)} />
                                            </p>

                                            <DAPconLinkMenu
                                                activeNavDAPcon={activeNavDAPcon}
                                                setActiveNavDAPcon={setActiveNavDAPcon}
                                                hideNav={hideNav}
                                                label='Ficha general'
                                                id={0}
                                                status='hidden'
                                            />

                                            <DAPconLinkMenu
                                                activeNavDAPcon={activeNavDAPcon}
                                                setActiveNavDAPcon={setActiveNavDAPcon}
                                                hideNav={hideNav}
                                                label='Seguimiento'
                                                id={1}
                                                status='hidden'
                                            />

                                            <ListGroup.Item onClick={() => history.push(`/empresas/${formDAPcon.organizacion?.org_cif}`)}>
                                                Ir a empresa <FontAwesomeIcon icon={faExternalLinkAlt} className='ms-2' />
                                            </ListGroup.Item>

                                        </ListGroup>

                                        {
                                            isModuleAdministrador(user?.modulo_app) &&
                                            <Button size='sm' variant='outline-primary' className='mt-3 d-flex justify-content-center' onClick={() => setVistaConsultorAdmin('consultor')}>
                                                Ver como consultor
                                            </Button>
                                        }
                                    </div>
                                </div>
                                <div className='col-xl-9' id='dapcon-col-end'>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="d-flex align-items-sm-center justify-content-between flex-wrap flex-column flex-sm-row">
                                                <div>
                                                    <DropdownLanguage
                                                        all_idiomas={formDAPcon.all_idiomas}
                                                        setIdiomaDAPcon={setIdiomaDAPcon}
                                                        idiomaDAPcon={idiomaDAPcon}
                                                        id_dapcon={match.params?.id}
                                                        handleShowModal__ModificarIdiomas={handleShowModal__ModificarIdiomas}
                                                        siempreActivo
                                                    />
                                                </div>

                                                <div className='d-flex'>
                                                    <ButtonSpinner
                                                        variant="outline-primary" className="px-3 d-flex align-items-center"
                                                        handleSubmit={() => handleShowModalCambiarEstado({})} fetchDataLoading={[fetchPublicarCaateebData.loading]}
                                                        value='Cambiar estado'
                                                    />
                                                    {
                                                        (formDAPcon.estadoDAPcons === 'pendiente_de_publicar' || formDAPcon.estadoDAPcons === 'publicado') &&
                                                        <div className='d-flex align-items-center'>
                                                            {/* <Button variant="danger" size='' className="">
                                                            <FontAwesomeIcon icon={faCheckDouble} className='me-2' /> 
                                                        </Button> */}
                                                            {
                                                                formDAPcon.fecha_publicacion_en_caateeb ?
                                                                    <>
                                                                        <OverlayTrigger
                                                                            overlay={<Tooltip id="tooltip-disabled">El DAPcons ya ha sido publicado</Tooltip>}
                                                                            placement='bottom'
                                                                            key='bottom'
                                                                        >
                                                                            <span className="d-inline-block">
                                                                                <ButtonSpinner
                                                                                    variant="danger" type="submit" className="px-3 d-flex align-items-center ms-3"
                                                                                    value='Publicar CAATEEB' icon={<FontAwesomeIcon icon={faCheckDouble} className='me-2' />}
                                                                                    disabled={true}
                                                                                />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </> : (
                                                                        <ButtonSpinner
                                                                            variant="danger" className="px-3 d-flex align-items-center ms-3"
                                                                            handleSubmit={handleClickPublicarCaateeb} fetchDataLoading={[fetchPublicarCaateebData.loading]}
                                                                            value='Publicar CAATEEB' icon={<FontAwesomeIcon icon={faCheckDouble} className='me-2' />}
                                                                        />
                                                                    )
                                                            }

                                                            {
                                                                (formDAPcon.isECOplatform && formDAPcon.fecha_publicacion_en_caateeb) ?
                                                                    (
                                                                        <>
                                                                            {
                                                                                formDAPcon.fecha_publicacion_en_ecoplatform ?
                                                                                    <OverlayTrigger
                                                                                        overlay={<Tooltip id="tooltip-disabled">El DAPcons ya ha sido publicado</Tooltip>}
                                                                                        placement='bottom'
                                                                                        key='bottom'
                                                                                    >
                                                                                        <span className="d-inline-block">
                                                                                            <ButtonSpinner
                                                                                                variant="success" type="submit" className="px-3 d-flex align-items-center ms-3"
                                                                                                value='Publicar ECOplatform' icon={<FontAwesomeIcon icon={faCheckDouble} className='me-2' />}
                                                                                                disabled={true}
                                                                                            />
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                                    : (
                                                                                        <ButtonSpinner
                                                                                            variant="success" className="px-3 d-flex align-items-center ms-3"
                                                                                            handleSubmit={handleClickPublicarECOplatform} fetchDataLoading={[fetchPublicarECOplatformData.loading]}
                                                                                            value='Publicar ECOplatform' icon={<FontAwesomeIcon icon={faCheckDouble} className='me-2' />}
                                                                                        />
                                                                                    )
                                                                            }
                                                                        </>
                                                                    )

                                                                    : <></>
                                                            }
                                                        </div>
                                                    }
                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                    {
                                        activeNavDAPcon === 0 &&
                                        <DAPconAdminSeccion0
                                            id={match.params?.id}
                                            setSeries={setSeries}
                                            series={series}
                                            handleChangeSerie={handleChangeSerie}
                                            fetchFichaResumenData={fetchFichaResumenData}
                                            dapcons_codigo={formDAPcon.dapcons_codigo}
                                            showModalCrearIncidencia={showModalCrearIncidencia}
                                            handleCloseModalCrearIncidencia={handleCloseModalCrearIncidencia}
                                            idiomaDAPcon={idiomaDAPcon}
                                            isECOplatform={formDAPcon.isECOplatform}
                                            formDAPcon={formDAPcon}
                                            handleInputChangeDAPcon={handleInputChangeDAPcon}
                                            consultorSelect={consultorSelect}
                                            setConsultorSelect={setConsultorSelect}

                                            fetchFichaResumen={fetchFichaResumen}
                                        />
                                    }
                                    {
                                        activeNavDAPcon === 1 &&
                                        <DAPconSeccionSeguimiento
                                            id={match.params?.id}
                                            fetchFichaResumenData={fetchFichaResumenData}
                                            dapcons_codigo={formDAPcon.dapcons_codigo}
                                            formDAPcon={formDAPcon}
                                            fetchEstadosDAPconsData={fetchEstadosDAPconsData}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </>
            }

            <ModalStatus setShowStatus={setShowSuccessSoli} showStatus={showSuccessSoli}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>
                        El DAPcons se ha publicado correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusError fetchData={fetchPublicarECOplatformData} />
            <ModalStatusError fetchData={fetchPublicarCaateebData} />


            <ModalCambiarEstado
                showModalCambiarEstado={showModalCambiarEstado}
                handleCloseModalCambiarEstado={handleCloseModalCambiarEstado}
                idiomaDAPcon={idiomaDAPcon}
                id={match.params?.id}
                fetchEstadosDAPconsData={fetchEstadosDAPconsData}
                fetchFichaResumen={fetchFichaResumen}
            />

            <ModalModificarIdiomas
                showModal__ModificarIdiomas={showModal__ModificarIdiomas}
                handleCloseModal__ModificarIdiomas={handleCloseModal__ModificarIdiomas}
                idiomaDAPcon={idiomaDAPcon}
                id={match.params?.id}
                fetchEstadosDAPconsData={fetchEstadosDAPconsData}
                fetchFichaResumen={fetchFichaResumen}
                handleForm={handleFormChangeDAPcon}
                form={formDAPcon}
                fetchSuccess={() => fetchFichaResumen({
                    params: {
                        idioma_id: idiomaDAPcon
                    }
                })}
            />
        </>
    )
}
