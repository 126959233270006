import React, { useContext, useEffect } from 'react'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, ListGroup, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import { isModuleConsultor, isModuleOrganizacion } from '../../helpers/helpers'
import { useAxios } from '../../hooks/useAxios'
import { PaginationT } from '../../components/PaginationT/PaginationT'
import { useForm } from '../../hooks/useForm'
import { Loader } from '../../components/Loader/Loader'

export const DashboardAdmin = () => {

    const history = useHistory()

    const [fetchOrganizacion, fetchOrganizacionData] = useAxios('get', '/cuotas-anuales')

    // useEffect(() => {

    //     fetchOrganizacion({
    //         params: {
    //             pagado: false
    //         }
    //     })

    // }, [])

    useEffect(() => {

        console.log('fetchOrganizacionData', fetchOrganizacionData);

    }, [fetchOrganizacionData]);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
        pagado: false,
        order_by: 'year desc',
    });


    useEffect(() => {

        fetchOrganizacion({
            params: {
                page: formValuesSearch.page,
                pagado: formValuesSearch.pagado,
                order_by: formValuesSearch.order_by
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order_by])

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Inicio
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="col-12 animate__animated animate__fadeInUp">
                {/* <Alert variant='warning'>
                    No esta definido el logotipo del consultor, <Alert.Link onClick={() => history.push('/mis-datos')}>haga click aquí</Alert.Link> para añadirlo
                </Alert> */}

                <div className="row">

                    <div className="col-12 col-lg-6">
                        <div className="MainInner shadow-sm mb-4">

                            <div className="d-flex justify-content-between">
                                <div className='flex-grow-1'>
                                    <h5 className='fs-105'>Cuotas anuales pendientes</h5>
                                    {
                                        fetchOrganizacionData?.loading ?
                                            <Loader /> :
                                            <ListGroup variant="flush" className='ListDashboard'>
                                                {
                                                    fetchOrganizacionData.data?.data?.data?.data?.map(item => {
                                                        return (
                                                            <ListGroup.Item onClick={() => history.push(`/empresas/${item.org_cif}?tab=PagosCuotas`)}> <strong className='fw-500 pe-2 cursor-pointer'>{item.year}</strong> - {item.org_nombre}</ListGroup.Item>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                    }

                                    <PaginationT
                                        total={fetchOrganizacionData?.data?.data?.data?.total}
                                        handleFormChange={handleFormChange}
                                        formValuesSearch={formValuesSearch}
                                        perPage={fetchOrganizacionData?.data?.data?.data?.per_page}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
