import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faUserCog, faListUl, faFileAlt, faCogs, faCheckCircle, faCheckSquare, faUser, faHome, faEnvelopeOpen, faUsers, faWarehouse, faList, faDollarSign, faMoneyBill, faArchive, faNewspaper, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../auth/AuthContext';
import { useLang } from '../../language/';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NavLinkMenu from './NavLinkMenu';
import { isAdmin, isCaateeb, isModuleAdministrador, isModuleConsultor, isModuleOrganizacion, isVerificador, isConsultor, isOrganizacion  } from '../../helpers/helpers';
import './NavLinksInner.css'

export const NavLinksInner = ({ close }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);
    console.log('perfil', perfil)
    const Lang = useLang();
    return (
        <div className="NavLinksInner py-2">

            <NavLinkMenu
                label='Inicio'
                to="/inicio"
                icon={<FontAwesomeIcon icon={faHome} />}
                close={close}
            />

            <NavLinkMenu
                label='DAPcons'
                to="/DAPcons"
                icon={<FontAwesomeIcon icon={faFileAlt} />}
                close={close}
            />

            {
                isVerificador(perfil) &&
                <NavLinkMenu
                    label='Verficar DAPcons'
                    to="/verificar-DAPcons"
                    icon={<FontAwesomeIcon icon={faCheckSquare} />}
                    close={close}
                />
            }
            {
                isAdmin(perfil) &&
                <NavLinkMenu
                    label={Lang('USUARIOS')}
                    to="/usuarios"
                    icon={<FontAwesomeIcon icon={faUsers} />}
                    close={close}
                />
            }
            {
                isModuleAdministrador(modulo_app) &&
                <>
                    <NavLinkMenu
                        label='Consultores'
                        to="/consultores"
                        icon={<FontAwesomeIcon icon={faUsers} />}
                        close={close}
                    />
                    <NavLinkMenu
                        label='Empresas'
                        to="/empresas"
                        icon={<FontAwesomeIcon icon={faWarehouse} />}
                        close={close}
                    />
                    {/* <NavLinkMenu
                        label='Pagos'
                        to="/pagos"
                        icon={<FontAwesomeIcon icon={faMoneyBill} />}
                        close={close}
                    /> */}
                    <NavLinkMenu
                        label='Plantillas'
                        to="/plantillas-mensajes"
                        icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
                        close={close}
                    />
                    <NavLinkMenu
                        label='Parámetros'
                        to="/parametros"
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        close={close}
                    />
                    <NavLinkMenu
                        label='RCPs'
                        to="/RCPs"
                        icon={<FontAwesomeIcon icon={faList} />}
                        close={close}
                    />
                </>
            }
            {/* {
                isAdmin(perfil) &&
                <>
                    <NavLinkMenu
                        label='Logs'
                        to="/logs"
                        icon={<FontAwesomeIcon icon={faListUl} />}
                        close={close}
                        disabled
                    />
                    <NavLinkMenu
                        label={Lang('MANTENIMIENTO')}
                        to="/maintenance"
                        icon={<FontAwesomeIcon icon={faCogs} />}
                        close={close}
                        disabled
                    />
                </>
            } */}
             {
                isAdmin(perfil) &&
                <>
                    <NavLinkMenu
                            label='Documentos'
                            to="/biblioteca-documentos"
                            icon={<FontAwesomeIcon icon={faArchive} />}
                            close={close}
                        />


                      <NavLinkMenu
                            label='Contenidos'
                            to="/contenidos"
                            icon={<FontAwesomeIcon icon={faNewspaper} />}
                            close={close}
                        />

                        <NavLinkMenu
                            label='Comunicaciones'
                            to="/comunicaciones"
                            icon={<FontAwesomeIcon icon={faEnvelope} />}
                            close={close}
                        />     
                </>
            }

            {
                (isConsultor(perfil) || isVerificador(perfil) || isOrganizacion(perfil))  &&
                <>
                    <NavLinkMenu
                            label='Documentos'
                            to="/public-documentos"
                            icon={<FontAwesomeIcon icon={faArchive} />}
                            close={close}
                        />    
                    {/*    
                    <NavLinkMenu
                            label='Noticias'
                            to="/public-noticias"
                            icon={<FontAwesomeIcon icon={faNewspaper} />}
                            close={close}
                        />  
                    */}          
                </>
            }          

            {
                <NavLinkMenu
                    label='Mis datos'
                    to="/mis-datos"
                    icon={<FontAwesomeIcon icon={faUser} />}
                    close={close}
                />
            }
        </div>
    )
}
