import React, { useEffect, useRef, useState } from 'react'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Form } from 'react-bootstrap'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { InputSelect, InputText } from '../../components/Inputs'
import { MsgInput } from '../../components/MsgInput/MsgInput'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer'
import { useForm } from '../../hooks/useForm'
import { useValidator } from '../../hooks/useValidator'
import { useAxios } from '../../hooks/useAxios'
import InputImage from '../../components/Inputs/InputImage'
import { ModalCropImage } from '../../components/ModalCropImage/ModalCropImage'
import { GetPaisJSON } from '../../services/pais.service'
import { ModalStatus } from '../../components/ModalStatusServer/ModalStatus'
import { ModalStatusError } from '../../components/ModalStatusServer/ModalStatusError'
import { ModalStatusServer2 } from '../../components/ModalStatusServer/ModalStatusServer2'

const ModalCrearConsultor = ({ showModalRegistroConsultor, handleCloseModalRegistroConsultor,
    handleShowModalProteccionDatos, setConsultor_manif_prot_datos_pers, consultor_manif_prot_datos_pers }) => {

    const inputFileRef = useRef();

    const [fetchRegistroConsultor, fetchRegistroConsultorData] = useAxios('post', `/auth/registroConsultor`)

    const [showStatusVerify2, setShowStatusVerify2] = useState(false);

    useEffect(() => {

        console.log(fetchRegistroConsultorData)

        if (fetchRegistroConsultorData.data?.status === 201) {
            handleCloseModalRegistroConsultor()
            setShowStatusVerify2(true)
        }

    }, [fetchRegistroConsultorData])

    const [formRegisterExterno, handleInputChangeRegisterExterno, handleFormChangeRegisterExterno, handleResetRegisterExterno] = useForm({
        usuario: '',
        email: '',
        password: '',
        password_confirmation: '',
        consultor_nombre: '',
        consultor_apellidos: '',
        consultor_movil: '',
        consultor_manif_prot_datos_pers: '',

        consultor_empresa_nombre: '',
        consultor_direccion: '',
        consultor_codigo_postal: '',
        consultor_poblacion: '',
        consultor_provincia: '',
        consultor_pais: '',
        consultor_web: '',
    })

    const [valuesValidRegistroConsultor, handleVerifiedValuesRegisterExterno, handleResetValuesRegisterExterno] = useValidator({
        usuario: { isOk: true, msgError: [`El documento es obligatorio.`] },
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
        password: { isOk: true, msgError: [`La contraseña és obligatoria.`] },
        password_confirmation: { isOk: true, msgError: [`La confirmación de contraseña es obligatoria.`] },
        consultor_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        consultor_apellidos: { isOk: true, msgError: [`Los apellidos son obligatorios.`] },
        consultor_movil: { isOk: true, msgError: [`El teléfono es obligatorio.`] },
        consultor_manif_prot_datos_pers: { isOk: true, msgError: [`Ha de aceptar la protección de datos`] },

        consultor_pais: { isOk: true, msgError: [`El país es obligatorio.`] },
        consultor_codigo_postal: { isOk: true, msgError: [`El código postal es obligatorio.`] },
        consultor_poblacion: { isOk: true, msgError: [`La población es obligatoria.`] },
        consultor_direccion: { isOk: true, msgError: [`La dirección es obligatoria.`] },
    })

    const handleSubmitFormRegisterExterno = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesRegisterExterno(formRegisterExterno)) {

            let formDataCrearConsultor = new FormData();

            for (const key in formRegisterExterno) {
                formDataCrearConsultor.append(key, formRegisterExterno[key])
            }

            if (InputFiles.length > 0) {
                formDataCrearConsultor.append("logo", InputFiles[0])
            }

            fetchRegistroConsultor({ body: formDataCrearConsultor })
        }
    }

    useEffect(() => {

        handleFormChangeRegisterExterno({
            ...formRegisterExterno,
            consultor_manif_prot_datos_pers: consultor_manif_prot_datos_pers ? consultor_manif_prot_datos_pers : ''
        })
    }, [consultor_manif_prot_datos_pers])


    useEffect(() => {
        console.log(formRegisterExterno)

    }, [formRegisterExterno])

    // Reseteando datos

    useEffect(() => {
        if (!showModalRegistroConsultor) {
            handleResetRegisterExterno()
            handleResetValuesRegisterExterno()
            setConsultor_manif_prot_datos_pers(false)
        }
    }, [showModalRegistroConsultor])


    // Recortar imagen

    const [InputFiles, setInputFiles] = useState([])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }


    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)

    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (activeEspana) {
            if (formRegisterExterno.consultor_codigo_postal?.length === 5) {

                fetchPoblaciones({
                    params: {
                        cp: formRegisterExterno.consultor_codigo_postal
                    }
                })
            } else {
                setPoblaciones([])
                handleFormChangeRegisterExterno({
                    ...formRegisterExterno,
                    consultor_provincia: '',
                    consultor_poblacion: '',
                    // pais: '',
                })
            }
        }

    }, [formRegisterExterno.consultor_codigo_postal])

    useEffect(() => {

        console.log(fetchPoblacionesData)

        // if (fetchPoblacionesData.data) {

        if (fetchPoblacionesData.data?.status === 201) {

            const poblaciones = fetchPoblacionesData.data?.data?.data

            let poblacionesTemp = poblaciones.map(item => {
                return ({
                    id: item.poblacion,
                    nombre: item.poblacion
                })
            })

            setPoblaciones(poblacionesTemp)

        } else {
            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                consultor_provincia: '',
                consultor_poblacion: '',
                // pais: '',
            })
        }
        // }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formRegisterExterno.consultor_poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data.data]

            console.log(poblacionesTemp)

            const resp = poblacionesTemp.find(item => item.poblacion === formRegisterExterno.consultor_poblacion)

            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
                consultor_provincia: resp?.provincia,
                // pais: resp?.pais,
            })
        }

    }, [formRegisterExterno.consultor_poblacion, fetchPoblacionesData.data])

    // funcionalidad de pais

    const [fetchPaises, fetchPaisesData] = GetPaisJSON()

    useEffect(() => {
        fetchPaises()
    }, [])


    const [activeEspana, setactiveEspana] = useState(false)

    useEffect(() => {

        if (formRegisterExterno.consultor_pais === '1' || formRegisterExterno.consultor_pais === 1) {

            setactiveEspana(true)
            fetchPoblaciones({
                params: {
                    cp: formRegisterExterno.org_codigo_postal
                }
            })
        } else {
            setactiveEspana(false)
            handleFormChangeRegisterExterno({
                ...formRegisterExterno,
            })
        }

    }, [formRegisterExterno.consultor_pais])

    return (

        <>
            <Modal show={showModalRegistroConsultor} onHide={handleCloseModalRegistroConsultor} size='lg' id='ModalRegistro'>
                <Modal.Header className=''>
                    <Modal.Title className='h5 mx-auto'>Registro de Consultor</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModalRegistroConsultor}></button>
                </Modal.Header>
                <Modal.Body className='p-0'>

                    <section style={{ 'backgroundColor': '#f2f4f6' }}>
                        <div className="container">
                            <div className="row pt-3 pb-4 px-3">
                                <div className="col-12">
                                    <h5 className='mb-4'>Datos personales</h5>
                                </div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                    <InputText
                                        label='NIF *'
                                        className=''
                                        name='usuario'
                                        value={formRegisterExterno.usuario}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-4">
                                    <InputText
                                        label='Nombre *'
                                        className=''
                                        name='consultor_nombre'
                                        value={formRegisterExterno.consultor_nombre}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-5">
                                    <InputText
                                        label='Apellidos *'
                                        className=''
                                        name='consultor_apellidos'
                                        value={formRegisterExterno.consultor_apellidos}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <div className="col-12">
                                    <h5 className='mb-3 mt-2'>Datos de empresa</h5>
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="row">
                                                <Form.Group className="mb-3 col-12">
                                                    <InputText
                                                        label='Nombre de la empresa'
                                                        className=''
                                                        name='consultor_empresa_nombre'
                                                        value={formRegisterExterno.consultor_empresa_nombre}
                                                        onChange={handleInputChangeRegisterExterno}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-lg-12">
                                                    <InputText
                                                        label='Dirección *'
                                                        className=''
                                                        name='consultor_direccion'
                                                        value={formRegisterExterno.consultor_direccion}
                                                        onChange={handleInputChangeRegisterExterno}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                    <InputSelect
                                                        label='País *'
                                                        className=''
                                                        name='consultor_pais'
                                                        // values={[]}
                                                        values={fetchPaisesData.data || []}
                                                        value={formRegisterExterno.consultor_pais}
                                                        onChange={handleInputChangeRegisterExterno}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    // disabled={!fetchPoblacionesData.data || formRegisterExterno.consultor_codigo_postal?.length !== 5}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                                    <InputText
                                                        label='Código Postal *'
                                                        className=''
                                                        name='consultor_codigo_postal'
                                                        value={formRegisterExterno.consultor_codigo_postal}
                                                        onChange={handleInputChangeRegisterExterno}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    />
                                                </Form.Group>

                                                {
                                                    activeEspana ? <>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-8">
                                                            <InputSelect
                                                                label='Población *'
                                                                className=''
                                                                name='consultor_poblacion'
                                                                // values={[]}
                                                                values={poblaciones}
                                                                value={formRegisterExterno.consultor_poblacion}
                                                                onChange={handleInputChangeRegisterExterno}
                                                                validation={valuesValidRegistroConsultor}
                                                                fetchData={fetchRegistroConsultorData}
                                                                disabled={!fetchPoblacionesData.data || formRegisterExterno.consultor_codigo_postal?.length !== 5}
                                                            />

                                                        </Form.Group>
                                                    </> : <>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                            <InputText
                                                                label='Región *'
                                                                className=''
                                                                name='consultor_poblacion'
                                                                value={formRegisterExterno.consultor_poblacion}
                                                                onChange={handleInputChangeRegisterExterno}
                                                                validation={valuesValidRegistroConsultor}
                                                                fetchData={fetchRegistroConsultorData}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                            <InputText
                                                                label='Ciudad *'
                                                                className=''
                                                                name='consultor_provincia'
                                                                value={formRegisterExterno.consultor_provincia}
                                                                onChange={handleInputChangeRegisterExterno}
                                                                validation={valuesValidRegistroConsultor}
                                                                fetchData={fetchRegistroConsultorData}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-sm-9 col-lg-12">
                                                    <InputImage
                                                        label='Logotipo'
                                                        width='100%'
                                                        height='13rem'
                                                        onChange={handleChangeInputImage}
                                                        InputFiles={InputFiles}
                                                        setInputFiles={setInputFiles}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                </div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                    <InputText
                                        label='Teléfono *'
                                        className=''
                                        name='consultor_movil'
                                        value={formRegisterExterno.consultor_movil}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Dirección electrónica *'
                                        className=''
                                        name='email'
                                        value={formRegisterExterno.email}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Web:'
                                        className=''
                                        name='consultor_web'
                                        value={formRegisterExterno.consultor_web}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>


                                <div className="col-12">
                                    <h5 className='mb-3 mt-2'>Datos de acceso</h5>
                                </div>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Contraseña*'
                                        className=''
                                        name='password'
                                        type='password'
                                        value={formRegisterExterno.password}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Repetir contraseña*'
                                        className=''
                                        type='password'
                                        name='password_confirmation'
                                        value={formRegisterExterno.password_confirmation}
                                        onChange={handleInputChangeRegisterExterno}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <div className="col-12" key='consultor_manif_prot_datos_pers'>
                                    <Form.Check
                                        type='checkbox'
                                        name='consultor_manif_prot_datos_pers'
                                        checked={formRegisterExterno.consultor_manif_prot_datos_pers}
                                        id='consultor_manif_prot_datos_pers'
                                        onChange={(e) => {
                                            if (e.target.checked === true) {
                                                e.preventDefault()
                                                handleShowModalProteccionDatos()
                                            }
                                            else {
                                                setConsultor_manif_prot_datos_pers(false)
                                            }
                                        }}
                                        label={
                                            <div className="cursor-pointer">
                                                He leído y acepto la <span className="text-decoration-underline">Información relativa a la protección de datos de carácter personal.</span>
                                            </div>
                                        }
                                    />
                                    <MsgInput obj={valuesValidRegistroConsultor} name='consultor_manif_prot_datos_pers' />
                                </div>

                                <div className="col-12 mt-2 text-center">
                                    <MsgInputServer fetchData={fetchRegistroConsultorData} className='mt-4' />
                                </div>

                            </div>
                        </div>
                    </section>
                </Modal.Body>
                <Modal.Footer className=''>
                    <Button variant="default" onClick={handleCloseModalRegistroConsultor} className='px-3'>
                        Sortir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-3 px-3"
                        handleSubmit={handleSubmitFormRegisterExterno} fetchDataLoading={[fetchRegistroConsultorData.loading]}
                        value='Registrar-me'
                    />
                </Modal.Footer>
            </Modal>
            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                escalas={[
                    { x: 1, y: 1, label: 'Cuadrado' },
                    { x: 4, y: 2, label: 'Rectángulo' }
                ]}
            />

            <ModalStatus setShowStatus={setShowStatusVerify2} showStatus={showStatusVerify2}>
                <div className="py-0 text-center">
                    {/* <h5 className='mb-4 fw-normal'>Confirmar registro</h5> */}
                    <p className='mb-0'>
                        { fetchRegistroConsultorData.data?.data?.message }
                        {/* Hemos enviado un enlace de confirmación a tu correo electrónico. */}
                        {/* <br />
                        Si no has recibido el enlace ponte en contacto con nosotros en el telf. 932 40 20 60. Antes, revisa tu bandeja de spam */}
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusServer2 fetchData={fetchRegistroConsultorData} onlyError />

        </>

    )
}

export default ModalCrearConsultor