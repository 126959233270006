import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';

import { useLang } from '../../language/';
import { AuthContext } from '../../auth/AuthContext';
import { ConsultoresContent } from './ConsultoresContent/ConsultoresContent';
import { Consultor } from './Consultor/Consultor';
import { useLocation } from 'react-router-dom';

export const Consultores = () => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    // console.log('useContext', colegio, perfil)
    // const colegio = {id: 1, nombre: 'EBCN'} 
    // const perfil = {id: 'administrador', nombre: 'Administrador'}
    const location = useLocation();
    console.log(location.pathname);

    const Lang = useLang();
    return (
        <div className="container-inner row justify-content-between">
            <Switch>
                <Route
                    // exact
                    path="/consultores/:id"
                    component={(props) => <Consultor {...props} namePage='Consultor' perfil={perfil} nameProcess='' />}
                />

                <Route
                    exact
                    path="/consultores"
                    component={() => <ConsultoresContent namePage='Consultores' perfil={perfil} />}
                />
            </Switch>
            {/* <Redirect to="/DAPcons" /> */}

        </div>
    )
}
