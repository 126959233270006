import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'

export const DocumentosFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    return (
        <div className='col-xl-3'>
            <div className='Column-small shadow-sm sticky-top'>
                <h5>{Lang('FILTRO')}</h5>
                <Form className='row' onSubmit={handleSearch}>
                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Buscar</Form.Label>
                        <Form.Control type="text" autoComplete="off" name='magic' value={formValuesSearch.magic} onChange={handleInputChangeSearch} placeholder="Buscar por título, descripción" />
                    </Form.Group>
                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Tipo de documento</Form.Label>
                        <Form.Select aria-label="Default select example" value={formValuesSearch.tipo_documento} name='tipo_documento' onChange={handleInputChangeSearch}>
                            <option>Seleccione</option>
                            {fetchParametersData?.data?.data?.data?.map((item) => {
                                return (
                                    <option key={item.pt_param_id} value={item.pt_param_id}>{item.pt_texto}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                     <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Estado</Form.Label>
                        <Form.Select aria-label="Default select example" value={formValuesSearch.estado} name='estado' onChange={handleInputChangeSearch}>
                            <option value="" selected>Seleccione</option>
                            {[
                                {
                                    id: 'Publicado', nombre: 'Publicado'
                                },
                                {
                                    id: 'Despublicado', nombre: 'Despublicado'
                                },
                            ].map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <div className="col-12 col-sm-12"></div>
                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
