import { Tooltip } from 'react-bootstrap'
import React from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';

const NavLinkMenu = ({to, close, icon, label, disabled}) => {
    return (
        <NavLink
            activeClassName="active"
            className={`d-block my-3 ${disabled ? 'disabledCursor' : ''}`}
            exact
            to={to}
        >
            {
                close ? <>
                    <OverlayTrigger overlay={<Tooltip className='NavStartInner-tooltip-inClose'>{label}</Tooltip>} placement='right'>
                        <div className="d-inline-block nav-link">
                            {icon}
                        </div>
                    </OverlayTrigger>
                </> : <>
                    <div className='nav-link'>
                        <div style={{ 'width': '1.5rem' }} className="me-2">
                            {icon}
                        </div>
                        <span>{label}</span>
                    </div>
                </>
            }
        </NavLink>
    )
}

export default NavLinkMenu