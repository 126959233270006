export const documentosAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        titulo: item.titulo || '',
        descripcion: item.descripcion || '',
        tipo_documento_nombre: item.tipo_documento_nombre || '',
        tipo_documento: item.tipo_documento || '',
        archivo: item.archivo || '',
        estado: item.estado || '',
        perfil: item.perfil || [],
        ruta: item.ruta || '',
        tipo: item.tipo || '',
        link: item.link || ''
    }

    return {
        ...data,
    }
}

export const documentosAdapter = (data) => {
    const dataTemp = data?.data.data
    const items = dataTemp?.data?.map(item => (documentosAdapterSchema(item)))
    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        },
        status: data?.status
    }
}

export const postDocumentoAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        titulo: item.titulo || '',
        descripcion: item.descripcion || '',
        tipo_documento: item.tipo_documento || '',
        archivo: item.archivo || '',
        estado: item.estado,
        perfil: item.perfil || [],
        tipo: item.tipo || '',
        link: item.link || ''
    }
    return data
}

export const patchDocumentoAdapterSchema = (item = {}) => {
    const data = {
        id: item.id || '',
        titulo: item.titulo || '',
        descripcion: item.descripcion || '',
        tipo_documento: item.tipo_documento || '',
        archivo: item.archivo || '',
        estado: item.estado,
        perfil: item.perfil || [],
        tipo: item.tipo || '',
        link: item.link || '',
        _method: 'PATCH'
    }
    return data
}

export const getDocumentoAdapter = (data) => {
    const dataTemp = data?.data?.data
    const item = documentosAdapterSchema(dataTemp)
    return {
        data: item,
        status: data?.status
    }
}


export const postDocumentoMultipar = (form) => {
    const formAdapter = postDocumentoAdapterSchema(form)
    let formData = new FormData();
    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
    }
    return formData
}

export const patchDocumentoMultipar = (form) => {
    const formAdapter = patchDocumentoAdapterSchema(form)
    let formData = new FormData();
    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
    }
    return formData
}
