import { faCheckCircle, faFile, faFilePdf, faHome, faSave, faTimes, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Breadcrumb, Button, Form, ListGroup, Accordion, Card, Table, InputGroup, FormControl } from 'react-bootstrap'
import { Loader } from '../../../components/Loader/Loader'
import { useAxios } from '../../../hooks/useAxios'
import { PaginationT } from '../../../components/PaginationT/PaginationT';
import { useForm } from '../../../hooks/useForm'
import './DocumentosPublic.css'

export const DocumentosPublic = () => {

    const [fetchDocumentos, fetchDocumentosData] = useAxios('GET', `/biblioteca-documentos-public`)
    const [fetchParameters, fetchParametersData] = useAxios('POST', `/parametros_generales`)
    const [activeLink, setactiveLink] = useState('general')

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, handleFormChangeReset] = useForm({
        magic: '',
        page: 1,
    });

    const handleSearch = (event) => {
        event.preventDefault();
        if (formValuesSearch.page !== 1) {
            fetchDocumentos({
                id: activeLink,
                params: {
                    ...formValuesSearch,
                    page: 1,
                }
            });

        } else {
            let formValuesSearchTemp = { ...formValuesSearch }
            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);
            fetchDocumentos({
                id: activeLink,
                params: {
                    ...formValuesSearchTemp,
                }
            })
        }
    };

    useEffect(() => {
        fetchParameters({
            body: [
                {
                    "param": "clasificacion_documento",
                    "locale": 1,
                    "active": true
                },
            ]
        });
    }, [])

    useEffect(() => {

        if(activeLink){
            handleFormChange({...formValuesSearch, magic: ''});
        }

        fetchDocumentos({
             id: activeLink,
             params: {
                ...formValuesSearch,
            }
        })
    }, [activeLink, formValuesSearch.page, formValuesSearch.orderby])

    return (
        <>
            <div className="container-inner row justify-content-between">
                <Breadcrumb className="Breadcrumb-inner">
                    <Breadcrumb.Item href="#">
                        <FontAwesomeIcon icon={faHome} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                          Listado de documentos:
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className='col-xl-3'>
                    <div className='Column-small shadow-sm sticky-top listgroup-plantillas'>
                        <h5>Tipo de documentos</h5>

                        {fetchParametersData?.data?.data?.data?.clasificacion_documento.length > 0
                            ?
                                 <ListGroup variant="flush" className='mt-3'>
                                    {
                                        fetchParametersData?.data?.data?.data?.clasificacion_documento?.map((item, index) => {

                                            return <ListGroup.Item key={item.param_id} className={`${item.param_id === activeLink ? 'active' : ''}`} onClick={() => setactiveLink(item.param_id)}>{index + 1}. {item.pt_texto}</ListGroup.Item>
                                        })
                                    }
                                </ListGroup>
                            : <Loader />
                        }
                    </div>
                </div>
                <div className="col-xl-9">
                    <div className="MainInner shadow-sm animate__animated animate__fadeInUp">

                     <div className="row d-flex justify-content-end"> 
                        <div className="col-12 col-md-4 mb-4">
                            <form onSubmit={handleSearch}>
                                <InputGroup>
                                  <FormControl
                                    placeholder="Buscar título, descripción"
                                    aria-label="Buscar"
                                    aria-describedby="basic-addon2"
                                    name="magic"
                                    value={formValuesSearch.magic} 
                                    onChange={handleInputChangeSearch}
                                  />
                                  <Button variant="primary" type="submit">Buscar</Button>
                                </InputGroup>
                              </form>
                          </div>  
                      </div> 

                     {fetchDocumentosData.loading ?
                                <div className="py-5">
                                    <Loader />
                                </div> : 
                                <>
                                {
                                    fetchDocumentosData?.data?.data?.data?.total ?
                                    <> 
                                        <Table hover bordered responsive className='tableMain table-sm table-striped'>
                                            <thead>
                                                <tr className="text-center">
                                                    <th className='text-nowrap'>Título</th>
                                                    <th className='text-nowrap'>Descripción</th>
                                                    <th className='text-center'>Documento</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {fetchDocumentosData?.data?.data?.data?.data?.map((documento, index) => (
                                                 <tr key={index} className="text-center">
                                                    <td valign='middle'>{documento.titulo}</td>
                                                    <td valign='middle'>{documento.descripcion}</td>
                                                    <td valign='middle'>
                                                        {documento.tipo === 'documento' &&
                                                            <Button href={documento.ruta} target="_blank" download variant="success" size='sm' className='ms-3 my-0'>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </Button>
                                                        }

                                                        {documento.tipo === 'enlace' &&
                                                            <Button href={documento.link} target="_blank" variant="success" size='sm' className='ms-3 my-0'>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </Button>
                                                        }
                                                    </td>
                                                 </tr>      
                                             ))
                                            }
                                             </tbody>
                                           </Table>        

                                           <PaginationT
                                                total={fetchDocumentosData?.data?.data?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchDocumentosData?.data?.data?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                      }  
                                   </>  
                            }                 
                    </div>
                </div>
            </div>
        </>

    )
}
