import React from "react";
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { Organizacion } from "./Organizacion";
import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { ListMenuRoutes } from "../../../components/ListMenu/ListMenuRoutes";
import { useLocation } from 'react-router-dom';
import { PagosCuotas } from "./PagosCuotas";
import { ListMenu } from "../../../components/ListMenu/ListMenu";
import { useState } from "react";
import { useEffect } from "react";
import { GetOrganizacionJSON } from "../../../services/organizacion.service";
import { PagosDAPcons } from "./PagosDAPcons";
import { isStatusSucess } from "../../../helpers/helpers";
import { useHistory } from 'react-router-dom';

export const OrganizacionRouter = ({ match }) => {

    const { id } = useParams();

    const { search } = useLocation();

    // console.log('search', search);

    const valueParamsTab = (new URLSearchParams(search)).get('tab') || ''

    console.log('id', id, match);

    // Lista de menus para organizacion

    const listMenu = {
        datosGenerales: {
            title: 'Datos generales'
        },
        PagosCuotas: {
            title: 'Pagos Cuotas'
        },
        pagosDapcons: {
            title: 'Pagos DAPcons'
        },
    }

    const [iDactive, setiDactive] = useState(valueParamsTab || 'datosGenerales');


    const [fetchOrganizacion, fetchOrganizacionData, resetFetchOrganizacion] = GetOrganizacionJSON()

    useEffect(() => {

        if (iDactive === 'datosGenerales' || (valueParamsTab && !fetchOrganizacionData.data)) {

            fetchOrganizacion({
                params: {
                    org_cif: match.params.id
                }
            })
        }


    }, [iDactive])

    const [nombreOrganizacion, setnombreOrganizacion] = useState('');

    useEffect(() => {
      if (isStatusSucess(fetchOrganizacionData?.status)) {

        setnombreOrganizacion(`${fetchOrganizacionData.data?.nombre} (${fetchOrganizacionData.data?.cif})`)
      }
    }, [fetchOrganizacionData]);

    const history = useHistory();

    return (
        <div>

            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('/empresas')}>
                    Empresas
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                   {nombreOrganizacion}
                </Breadcrumb.Item>
            </Breadcrumb>


            <div className="row d-flex justify-content-center">

                <div className="col-xl-3 mb-3">
                    <div className="shadow-sm">
                        <ListMenu
                            values={listMenu}
                            setiDactive={setiDactive}
                            iDactive={iDactive}
                        />
                    </div>
                </div>
                <div className="col-12 col-xl-9">

                    {
                        iDactive === 'datosGenerales' &&
                        <Organizacion
                            match={match}
                            fetchOrganizacionData={fetchOrganizacionData}
                            fetchOrganizacion={fetchOrganizacion}
                        />
                    }

                    {
                        iDactive === 'PagosCuotas' &&
                        <PagosCuotas
                            match={match}
                            fetchOrganizacionData={fetchOrganizacionData}
                        />
                    }

                    {
                        iDactive === 'pagosDapcons' &&
                        <PagosDAPcons
                            match={match}
                            fetchOrganizacionData={fetchOrganizacionData}
                        />
                    }

                    {/* <Switch>

                        <Route
                            exact
                            path="/empresas/:id/datos-generales"
                            component={() => <Organizacion match={match} />}
                        />

                        <Route
                            exact
                            path="/empresas/:id/pagos-cuotas"
                            component={() => <PagosCuotas id={id} /> }
                        />

                        <Route
                            exact
                            path="/empresas/:id/dapcons-relacionados"
                            component={() => <>hola 2</>}
                        />

                        <Redirect to={`/empresas/${id}/datos-generales`} />

                    </Switch> */}

                </div>
            </div>


        </div>
    );
};
