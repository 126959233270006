import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { images } from '../../helpers/images';
import { NavLinksInner } from '../NavLinksInner/NavLinksInner';
import { AuthContext } from '../../auth/AuthContext';
import { useLang } from '../../language';
import { types } from '../../types/types';

import './NavStartInner.css'

export const NavStartInner = ({ setShow, close }) => {

    const { user, dispatch } = useContext(AuthContext);

    console.log(close)
    // Cambiar idioma
    const Lang = useLang();
    const handdleChangeLanguage = (lang) => {

        dispatch({
            type: types.login,
            payload: {
                ...user,
                lang
            }
        })
    }

    return (
        <section className={`NavStartInner position-fixed d-flex flex-column ${close === 'true' || close === true ? 'toggle-menu' : ''}`} >
            {/* <a class="d-block mt-3 pt-0" href="">
                <div class="nav-link mt-0 pt-0">
                    <div class="me-2 mt-0 pt-2 mb-0"></div>
                    <span>{user.perfil}</span>
                </div>
            </a> */}
            <NavLinksInner close={close} />

            <footer className="px-3 mb-3 mt-auto">
                <div className='mb-3'>
                    {
                        process.env.REACT_APP_MULTILANGUAGE === 'true' && <Dropdown drop='up' >
                            <Dropdown.Toggle size="sm" variant="outline-light" id="dropdown-basic">
                                <FontAwesomeIcon icon={faGlobe} className='me-2' />
                                <span className='me-2'>{user.lang}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handdleChangeLanguage('ES')}>{Lang('ESPANIOL')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handdleChangeLanguage('CA')}>{Lang('CATALAN')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }

                </div>
                {/* <small>
                    <p className='mb-1'> {Lang('COPYRIGHT')}</p>
                    <ul className="list-none m-0 p-0 d-flex flex-wrap">
                        <li className=" mb-0 me-2">
                            <a href="https://www.apabcn.cat/ca_es/altres/Pagines/avislegal.aspx" target='_blank' rel="noopener noreferrer">Aviso legal y Politica de privacidad</a>
                        </li>
                        <li className=" mb-0 me-2">
                            <a href="https://www.apabcn.cat/ca_es/altres/Pagines/avislegal.aspx" target='_blank' rel="noopener noreferrer">Cookies</a>
                        </li>
                    </ul>
                </small> */}
            </footer>
        </section>
    )
}
