import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { faBars, faCheck, faCheckCircle, faCheckDouble, faCircle, faFile, faFilePdf, faHome, faPencilAlt, faSave, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Breadcrumb, Button, Form, ListGroup, Modal } from 'react-bootstrap'
import { useLang } from '../../../language';

import { AuthContext } from '../../../auth/AuthContext';
import { useAxios } from '../../../hooks/useAxios';
import { Loader } from '../../../components/Loader/Loader';
import { InputFile, InputSelect, InputText } from '../../../components/Inputs';
import InputImage from '../../../components/Inputs/InputImage';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import { GetOrganizacionJSON, getOrganizacionJSON, PostOrganizacionJSON } from '../../../services/organizacion.service';
import { getOrganizacionAdapterSchema, getOrganizacionAdapterSchemaMisDatos } from '../../../adapters/organizacion.adapter';
import { FileList } from '../../../components/FileList/FileList';
import { ModalStatus } from '../../../components/ModalStatusServer/ModalStatus';
import { GetPaisJSON } from '../../../services/pais.service';
import { getDataFile, isAdmin, isStatusSucess } from '../../../helpers/helpers';
import { ModalStatusServer } from '../../../components/ModalStatusServer/ModalStatusServer';
import { ModalStatusError } from '../../../components/ModalStatusServer/ModalStatusError';
import { ModalCropImage } from '../../../components/ModalCropImage/ModalCropImage';
import { ListMenuRoutes } from '../../../components/ListMenu/ListMenuRoutes';
import jwt from 'jwt-decode' // import dependency
import { types } from '../../../types/types';

export const Organizacion = ({ match, namePage, nameDAPcon, fetchOrganizacionData, fetchOrganizacion }) => {

    const { user: { perfil } } = useContext(AuthContext);

     const { dispatch } = useContext(AuthContext);

    const [formOrganizacion, handleInputChangeOrganizacion, handleFormChangeOrganizacion, handleResetOrganizacion]
        = useForm(getOrganizacionAdapterSchemaMisDatos())

    const [fetchLoginEmpresa, fetchLoginEmpresaData] = useAxios('get', `admin/auth/organizacion`)

    const handleSubmitIngresoEmpresa = () => {
        fetchLoginEmpresa({
            id:formOrganizacion?.usuario
        })
    }

    useEffect(() => {
       if(fetchLoginEmpresaData?.data?.data?.data?.token){
            localStorage.clear();
            const token = fetchLoginEmpresaData?.data?.data?.data?.token;
            const lastPath = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_lastPath') || '/';
            const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;
            const data = jwt(token)
            dispatch({
                type: types.login,
                payload: {
                    ...data,
                    token: token,
                },
                lang
            });
       }  
    },[fetchLoginEmpresaData.data])

    useEffect(() => {

        console.log(fetchOrganizacionData)

    }, [fetchOrganizacionData])


    useEffect(() => {

        if (fetchOrganizacionData.status === 200) {

            handleFormChangeOrganizacion(fetchOrganizacionData.data)
        }

    }, [fetchOrganizacionData])


    const [fetchSendOrganizacion, fetchSendOrganizacionData] = PostOrganizacionJSON()

    const handleSubmitActualizarEmpresa = () => {

        if (handleVerifiedValuesRegisterEmpresa(formOrganizacion)) {

            fetchSendOrganizacion({ body: formOrganizacion })
        }
    }

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {
        console.log(fetchSendOrganizacionData)

        if (fetchSendOrganizacionData?.status === 201) {
            setShowSuccessEdit(true)
        }

    }, [fetchSendOrganizacionData])

    const [valuesValidRegistroEmpresa, handleVerifiedValuesRegisterEmpresa, handleResetValuesRegisterEmpresa] = useValidator({
        cif: { isOk: true, msgError: [`El documento es obligatorio.`] },
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        direccion: { isOk: true, msgError: [`La dirección es obligatoria.`] },
        codigo_postal: { isOk: true, msgError: [`El código postal es obligatorio.`] },
        pais: { isOk: true, msgError: [`El país es obligatorio.`] },
        poblacion: { isOk: true, msgError: [`La población es obligatoria.`] },
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
        telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },

        persona_contacto_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        persona_contacto_cargo: { isOk: true, msgError: [`El cargo es obligatorio.`] },
        persona_contacto_telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },
        persona_contacto_email: { isOk: true, msgError: [`El email es obligatorio.`] },

        contacto_facturacion_nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        contacto_facturacion_email: { isOk: true, msgError: [`El email es obligatorio.`] },
        contacto_facturacion_telefono: { isOk: true, msgError: [`El teléfono es obligatorio.`] },
    })

    const [fetchGetImage, fetchGetImageData] = useAxios('get', `/org-logo-img`, '', 'blob')

    useEffect(() => {

        if (formOrganizacion.logo && typeof formOrganizacion.logo === 'string') {

            fetchGetImage({
                id: `${formOrganizacion.id}/img?token_img=${formOrganizacion.logo}`
            })
        }

    }, [formOrganizacion.logo])

    useEffect(() => {

        if (fetchGetImageData.data) {

            console.log(fetchGetImageData.data.data)
            setInputFiles([new File([fetchGetImageData.data?.data], 'logo.png')])
        }

    }, [fetchGetImageData])

    // Recortar imagen

    const [InputFiles, setInputFiles] = useState([])

    useEffect(() => {

        handleFormChangeOrganizacion({
            ...formOrganizacion,
            logo: InputFiles?.[0],
        })

    }, [InputFiles])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }

    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)
    // const [fetchPoblaciones, fetchPoblacionesData] = getPoblacionesJSON()()

    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (activeEspana) {

            if (formOrganizacion.codigo_postal?.length === 5) {

                fetchPoblaciones({
                    params: {
                        cp: formOrganizacion.codigo_postal
                    }
                })
            } else {
                setPoblaciones([])
                handleFormChangeOrganizacion({
                    ...formOrganizacion,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }
        }

    }, [formOrganizacion.codigo_postal])

    useEffect(() => {

        console.log(fetchPoblacionesData)

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data?.status === 201) {

                const poblaciones = fetchPoblacionesData.data?.data?.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeOrganizacion({
                    ...formOrganizacion,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }
        }

        if (fetchPoblacionesData.error) {
            handleFormChangeOrganizacion({
                ...formOrganizacion,
                provincia: '',
                poblacion: '',
                // pais: '',
            })
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formOrganizacion.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data.data]

            console.log(poblacionesTemp)

            const resp = poblacionesTemp.find(item => item.poblacion === formOrganizacion.poblacion)

            handleFormChangeOrganizacion({
                ...formOrganizacion,
                provincia: resp?.provincia,
                // pais: resp?.pais,
            })
        }

    }, [formOrganizacion.poblacion, fetchPoblacionesData.data])


    // Registro subir archivos

    const [fetchRegistroEmpresaFileISO, fetchRegistroEmpresaFileISOData] = useAxios('post', `/organizaciones/actualizar/documentos?_method=PATCH`)
    const [fetchRegistroEmpresaFileEMACS, fetchRegistroEmpresaFileEMACSData] = useAxios('post', `/organizaciones/actualizar/documentos?_method=PATCH`)

    const [formOrganizacionFiles, handleInputChangeRegisterEmpresaFiles,
        handleFormChangeRegisterEmpresaFiles, handleResetRegisterEmpresaFiles] = useForm({
            file_iso14001: '',
            file_emacs: '',
        })


    useEffect(() => {

        if (formOrganizacion.id) {

            handleFormChangeRegisterEmpresaFiles({
                file_iso14001: formOrganizacion.sgm_iso14001 ? true : '',
                file_emacs: formOrganizacion.sgm_emacs ? true : '',
            })
        }

    }, [formOrganizacion.sgm_iso14001, formOrganizacion.sgm_emacs])


    // Checks para adjuntar documentacio nde acreditacion

    const [valuesValidISO, handleVerifiedValuesISO, handleResetValuesISO] = useValidator({
        file_iso14001: { isOk: true, msgError: [`Es necesario adjuntar el documento acreditativo`] },
    })

    const [valuesValidEmacs, handleVerifiedValuesEmacs, handleResetValuesEmacs] = useValidator({
        file_emacs: { isOk: true, msgError: [`Es necesario adjuntar el documento acreditativo`] },
    })

    // Descargar pdf

    const [fetchDocumento, fetchDocumentoData, resetfetchDocumentoData] = useAxios('get', `/organizaciones`, '', 'blob')

    const handleFetchDocumento = (name, type) => {

        fetchDocumento({
            id: formOrganizacion.id + '/obtener-documento',
            params: {
                token_doc: formOrganizacion[name],
                doc_type: type,
            }
        })
    }

    useEffect(() => {

        if (fetchDocumentoData.data) {
            getDataFile(fetchDocumentoData, resetfetchDocumentoData)
        }
    }, [fetchDocumentoData])

    const [activeClick, setActiveClick] = useState()


    // funcionalidad de pais

    const [fetchPaises, fetchPaisesData] = GetPaisJSON()

    useEffect(() => {
        fetchPaises()
    }, [])


    const [activeEspana, setactiveEspana] = useState(false)

    useEffect(() => {

        if (formOrganizacion.pais === '1' || formOrganizacion.pais === 1) {

            setactiveEspana(true)
            fetchPoblaciones({
                params: {
                    cp: formOrganizacion.codigo_postal
                }
            })
        } else {
            setactiveEspana(false)
            handleFormChangeOrganizacion({
                ...formOrganizacion
            })
        }

    }, [formOrganizacion.pais])


    // Activar usuario

    const [fetchActivarUsuario, fetchActivarUsuarioData] = useAxios('post', `/usuario-admin/${formOrganizacion.usuario}/activar-usuario`)

    useEffect(() => {

        if (fetchActivarUsuarioData.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchOrganizacion({
                params: {
                    org_cif: match.params.id
                }
            })
        }

    }, [fetchActivarUsuarioData])

    // Modal

    const [showModalDarDeBaja, setShowModalDarDeBaja] = useState(false);

    const handleCloseModalDarDeBaja = () => setShowModalDarDeBaja(false)
    const handleShowModalDarDeBaja = () => setShowModalDarDeBaja(true);


    const [fetchDarDeBaja, fetchDarDeBajaData, resetfetchDarDeBaja] = useAxios('patch', `/organizaciones/${formOrganizacion.id}/dar_de_baja`)

    const [formDarDeBaja, handleInputChangeDarDeBaja] = useForm({
        org_fecha_baja: '',
        org_observaciones_baja: '',
    })


    const handleSubmitDarDeBaja = () => {

        fetchDarDeBaja({
            body: {
                ...formDarDeBaja
            }
        })
    }

    // Dar de alta

    const [showModalDarDeAlta, setShowModalDarDeAlta] = useState(false);

    const handleCloseModalDarDeAlta = () => setShowModalDarDeAlta(false)
    const handleShowModalDarDeAlta = () => setShowModalDarDeAlta(true);


    const [fetchDarDeAlta, fetchDarDeAltaData, resetfetchDarDeAlta] = useAxios('patch', `/organizaciones/${formOrganizacion.id}/dar_de_alta`)

    const [formDarDeAlta, handleInputChangeDarDeAlta] = useForm({
        org_fecha_alta: '',
        org_observaciones_baja: '',
    })

    const handleSubmitDarDeAlta = () => {

        fetchDarDeAlta({
            body: {
                ...formDarDeAlta
            }
        })
    }

    useEffect(() => {

        if (fetchDarDeAltaData.data?.status === 200 || fetchDarDeBajaData.data?.status === 200) {
            handleCloseModalDarDeAlta()
            handleCloseModalDarDeBaja()
            resetfetchDarDeBaja()
            resetfetchDarDeAlta()
            fetchOrganizacion({
                params: {
                    org_cif: match.params.id
                }
            })
        }

    }, [fetchDarDeAltaData, fetchDarDeBajaData])

    const handleChangeIntegracionApi = (e) => {

        handleFormChangeOrganizacion({
            ...formOrganizacion,
            integracion_api: e.target.checked ? true : ''
        })
    }


    return (
        <>

            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                {
                    fetchOrganizacionData.loading ?
                        <div className="py-5">
                            <Loader />
                        </div> : <>
                            <div className="row pt-lg-3 pb-lg-4 px-lg-3 w-100">
                                <div className="col-12">
                                    <div className="d-flex mb-3 mt-0 justify-content-between flex-column flex-lg-row">
                                        <h5 className='mt-0'>Datos de la empresa</h5>
                                        <div>
                                            <Form.Check
                                                type='checkbox'
                                                id='A1'
                                                label='Habilitar acceso API Rest'
                                                className='mb-2'
                                                name='integracion_api'
                                                checked={formOrganizacion.integracion_api}
                                                onChange={handleChangeIntegracionApi}
                                            />
                                            {
                                                formOrganizacion.org_fecha_baja && <span className='text-danger me-3'>Empresa dada de baja</span>
                                            }
                                            {
                                                !formOrganizacion.estaActivo &&
                                                <ButtonSpinner
                                                    variant="danger" type="submit" className=""
                                                    handleSubmit={fetchActivarUsuario} fetchDataLoading={[fetchActivarUsuarioData.loading]}
                                                    value='Activar' icon={<FontAwesomeIcon icon={faCheck} className='me-1' />}
                                                />
                                            }
                                        </div>
                                    </div>

                                </div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                    <Form.Label className='fw-500'>CIF:</Form.Label>
                                    <p className='mt-0 mb-0'>{formOrganizacion.cif}</p>
                                </Form.Group>

                                {
                                    isAdmin(perfil) ? (
                                        <Form.Group className="mb-3 col-lg-9">
                                            <InputText
                                                label='Razón social *'
                                                className=''
                                                name='nombre'
                                                value={formOrganizacion.nombre}
                                                onChange={handleInputChangeOrganizacion}
                                                validation={valuesValidRegistroEmpresa}
                                                fetchData={fetchSendOrganizacionData}
                                            />
                                        </Form.Group>
                                    )
                                        : (
                                            <Form.Group className="mb-3 col-lg-4">
                                                <Form.Label className='fw-500'>Razón social:</Form.Label>
                                                <p className='mt-0 mb-0'>{formOrganizacion.nombre}</p>
                                            </Form.Group>

                                        )
                                }

                                <div className="col-12"></div>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-8">

                                            <div className="row">
                                                <Form.Group className="mb-3 col-lg-12">
                                                    <InputText
                                                        label='Dirección *'
                                                        className=''
                                                        name='direccion'
                                                        value={formOrganizacion.direccion}
                                                        onChange={handleInputChangeOrganizacion}
                                                        validation={valuesValidRegistroEmpresa}
                                                        fetchData={fetchSendOrganizacionData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                    <InputSelect
                                                        label='País *'
                                                        className=''
                                                        name='pais'
                                                        // values={[]}
                                                        values={fetchPaisesData.data || []}
                                                        value={formOrganizacion.pais}
                                                        onChange={handleInputChangeOrganizacion}
                                                        validation={valuesValidRegistroEmpresa}
                                                        fetchData={fetchSendOrganizacionData}
                                                    // disabled={!fetchPoblacionesData.data || formOrganizacion.codigo_postal?.length !== 5}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                                    <InputText
                                                        label='Código Postal *'
                                                        className=''
                                                        name='codigo_postal'
                                                        value={formOrganizacion.codigo_postal}
                                                        onChange={handleInputChangeOrganizacion}
                                                        validation={valuesValidRegistroEmpresa}
                                                        fetchData={fetchSendOrganizacionData}
                                                    />
                                                </Form.Group>


                                                {
                                                    activeEspana ? <>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-8">
                                                            <InputSelect
                                                                label='Población *'
                                                                className=''
                                                                name='poblacion'
                                                                // values={[]}
                                                                values={poblaciones}
                                                                value={formOrganizacion.poblacion}
                                                                onChange={handleInputChangeOrganizacion}
                                                                validation={valuesValidRegistroEmpresa}
                                                                fetchData={fetchSendOrganizacionData}
                                                                disabled={!fetchPoblacionesData.data || formOrganizacion.codigo_postal?.length !== 5}
                                                            />

                                                        </Form.Group>
                                                    </> : <>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                            <InputText
                                                                label='Región *'
                                                                className=''
                                                                name='poblacion'
                                                                value={formOrganizacion.poblacion}
                                                                onChange={handleInputChangeOrganizacion}
                                                                validation={valuesValidRegistroEmpresa}
                                                                fetchData={fetchSendOrganizacionData}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                            <InputText
                                                                label='Ciudad *'
                                                                className=''
                                                                name='provincia'
                                                                value={formOrganizacion.provincia}
                                                                onChange={handleInputChangeOrganizacion}
                                                                validation={valuesValidRegistroEmpresa}
                                                                fetchData={fetchSendOrganizacionData}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                }

                                                <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                    <InputText
                                                        label='Teléfono *'
                                                        className=''
                                                        name='telefono'
                                                        value={formOrganizacion.telefono}
                                                        onChange={handleInputChangeOrganizacion}
                                                        validation={valuesValidRegistroEmpresa}
                                                        fetchData={fetchSendOrganizacionData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-lg-7">
                                                    <InputText
                                                        label='Dirección electrónica *'
                                                        className=''
                                                        name='email'
                                                        value={formOrganizacion.email}
                                                        onChange={handleInputChangeOrganizacion}
                                                        validation={valuesValidRegistroEmpresa}
                                                        fetchData={fetchSendOrganizacionData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-lg-12">
                                                    <InputText
                                                        label='Web'
                                                        className=''
                                                        name='web'
                                                        value={formOrganizacion.web}
                                                        onChange={handleInputChangeOrganizacion}
                                                        fetchData={fetchSendOrganizacionData}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-sm-9 col-lg-12">
                                                    <InputImage
                                                        label='Logotipo'
                                                        width='100%'
                                                        height='13rem'
                                                        onChange={handleChangeInputImage}
                                                        InputFiles={InputFiles}
                                                        setInputFiles={setInputFiles}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12"></div>


                                <div className="col-12">
                                    <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                </div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-6">
                                    <InputText
                                        label='Persona de contacto *'
                                        className=''
                                        name='persona_contacto_nombre'
                                        value={formOrganizacion.persona_contacto_nombre}
                                        onChange={handleInputChangeOrganizacion}
                                        validation={valuesValidRegistroEmpresa}
                                        fetchData={fetchSendOrganizacionData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-sm-7 col-lg-5">
                                    <InputText
                                        label='Cargo *'
                                        className=''
                                        name='persona_contacto_cargo'
                                        value={formOrganizacion.persona_contacto_cargo}
                                        onChange={handleInputChangeOrganizacion}
                                        validation={valuesValidRegistroEmpresa}
                                        fetchData={fetchSendOrganizacionData}
                                    />
                                </Form.Group>

                                <div className="col-12"></div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                    <InputText
                                        label='Teléfono *'
                                        className=''
                                        name='persona_contacto_telefono'
                                        value={formOrganizacion.persona_contacto_telefono}
                                        onChange={handleInputChangeOrganizacion}
                                        validation={valuesValidRegistroEmpresa}
                                        fetchData={fetchSendOrganizacionData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Dirección electrónica *'
                                        className=''
                                        name='persona_contacto_email'
                                        value={formOrganizacion.persona_contacto_email}
                                        onChange={handleInputChangeOrganizacion}
                                        validation={valuesValidRegistroEmpresa}
                                        fetchData={fetchSendOrganizacionData}
                                    />
                                </Form.Group>

                                <div className="col-12">
                                    <h5 className='mb-3 mt-2'>Contacto para facturación</h5>
                                </div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-6">
                                    <InputText
                                        label='Persona de contacto para facturación *'
                                        className=''
                                        name='contacto_facturacion_nombre'
                                        value={formOrganizacion.contacto_facturacion_nombre}
                                        onChange={handleInputChangeOrganizacion}
                                        validation={valuesValidRegistroEmpresa}
                                        fetchData={fetchSendOrganizacionData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                    <InputText
                                        label='Teléfono *'
                                        className=''
                                        name='contacto_facturacion_telefono'
                                        value={formOrganizacion.contacto_facturacion_telefono}
                                        onChange={handleInputChangeOrganizacion}
                                        validation={valuesValidRegistroEmpresa}
                                        fetchData={fetchSendOrganizacionData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Dirección electrónica *'
                                        className=''
                                        name='contacto_facturacion_email'
                                        value={formOrganizacion.contacto_facturacion_email}
                                        onChange={handleInputChangeOrganizacion}
                                        validation={valuesValidRegistroEmpresa}
                                        fetchData={fetchSendOrganizacionData}
                                    />
                                </Form.Group>

                                {/* <div className="col-12 mt-2 text-center">
                                            <MsgInputServer fetchData={fetchRegistroEmpresaData} className='mt-4' />
                                        </div> */}

                            </div>
                            <div className=' pt-3 d-flex justify-content-between border-top'>

                                <div>
                                    {
                                        formOrganizacion.org_fecha_baja ? (
                                            <Button variant='success' onClick={handleShowModalDarDeAlta}>
                                                Dar de alta
                                            </Button>
                                        ) : (
                                            <Button variant='danger' onClick={handleShowModalDarDeBaja}>
                                                Dar de baja
                                            </Button>
                                        )
                                    }

                                </div>

                                {
                                    isAdmin(perfil) &&
                                    <ButtonSpinner
                                        variant="success" type="submit" className="me-2"
                                        handleSubmit={handleSubmitIngresoEmpresa} fetchDataLoading={[fetchLoginEmpresaData.loading]}
                                        value='Login Empresa' icon={<FontAwesomeIcon icon={faUser} className='me-1' />}
                                    /> 
                                }

                                <ButtonSpinner
                                    variant="info" type="submit" className="me-2"
                                    handleSubmit={handleSubmitActualizarEmpresa} fetchDataLoading={[fetchSendOrganizacionData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                />
                            </div>
                        </>
                }
            </div>

            {
                (formOrganizacion.sgm_iso14001 || formOrganizacion.sgm_emacs) &&

                <div className='MainInner shadow-sm mt-4 mb-5 animate__animated animate__fadeInUp'>

                    {
                        fetchOrganizacionData.loading ?
                            <div className="py-5">
                                <Loader />
                            </div> :
                            <>
                                <div className="row pt-3 pb-4 px-3">
                                    <div className="col-12">
                                        <h5 className='mb-3 mt-0'>Sistemas de gestión medioambiental de la empresa</h5>
                                    </div>

                                    {
                                        formOrganizacion.sgm_iso14001 &&
                                        <>
                                            <div className="col-12 mt-2 pb-2" key='iso14001'>
                                                <div>La empresa dispone de la acreditación Sistema de gestión Medioambiental ISO 14001.</div>
                                                <div>(adjuntar documento acreditativo)</div>
                                            </div>

                                            <div className='d-flex align-items-center Files__container mt-2'>
                                                <ListGroup className='w-50 d-flex align-items-center Files__container'>
                                                    <FileList
                                                        eventClick={() => {
                                                            handleFetchDocumento('sgm_iso14001', 'iso14001')
                                                            setActiveClick('iso14001')
                                                        }}
                                                        name='ISO 14001'
                                                        isLoading={fetchDocumentoData.loading && activeClick === 'iso14001'}
                                                    />
                                                </ListGroup>

                                            </div>
                                        </>
                                    }

                                    {
                                        formOrganizacion.sgm_emacs &&
                                        <>
                                            <div className="col-12 mt-4 pb-2" key='emacs'>
                                                <div>La empresa dispone de la acreditación Sistema de gestión Medioambiental EMAS.</div>
                                                <div>(adjuntar documento acreditativo)</div>
                                            </div>
                                            <div className='d-flex align-items-center Files__container mt-2'>
                                                <ListGroup className='w-50 d-flex align-items-center Files__container'>
                                                    <FileList
                                                        eventClick={() => {
                                                            handleFetchDocumento('sgm_emacs', 'emacs')
                                                            setActiveClick('emacs')
                                                        }}
                                                        name='EMAS'
                                                        isLoading={fetchDocumentoData.loading && activeClick === 'emacs'}
                                                    />
                                                </ListGroup>

                                            </div>
                                        </>
                                    }

                                </div>
                            </>
                    }

                </div>
            }

            <Modal show={showModalDarDeBaja} onHide={handleCloseModalDarDeBaja} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Baixa dorganització</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>

                        <Form.Group className="mb-3 col-sm-12 col-lg-4">
                            <InputText
                                label='Data de baixa:'
                                type='date'
                                className=''
                                name='org_fecha_baja'
                                value={formDarDeBaja.org_fecha_baja}
                                onChange={handleInputChangeDarDeBaja}
                                fetchData={fetchDarDeBajaData}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-sm-12 col-lg-12">
                            <InputText
                                label='Observacions:'
                                as={'textarea'}
                                rows={3}
                                className=''
                                name='org_observaciones_baja'
                                value={formDarDeBaja.org_observaciones_baja}
                                onChange={handleInputChangeDarDeBaja}
                                fetchData={fetchDarDeBajaData}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleCloseModalDarDeBaja}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="info" type="submit" className="ms-2"
                        handleSubmit={handleSubmitDarDeBaja} fetchDataLoading={[fetchDarDeBajaData.loading]}
                        value='Dar de baja'
                    />
                </Modal.Footer>
            </Modal>

            <Modal show={showModalDarDeAlta} onHide={handleCloseModalDarDeAlta} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h5'>Alta dorganització</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4 py-4'>

                    <Form className='row'>

                        <Form.Group className="mb-3 col-sm-12 col-lg-4">
                            <InputText
                                label={`Data d'alta:`}
                                type='date'
                                className=''
                                name='org_fecha_alta'
                                value={formDarDeAlta.org_fecha_alta}
                                onChange={handleInputChangeDarDeAlta}
                                fetchData={fetchDarDeAltaData}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-sm-12 col-lg-12">
                            <InputText
                                label='Observacions:'
                                as={'textarea'}
                                rows={3}
                                className=''
                                name='org_observaciones_baja'
                                value={formDarDeAlta.org_observaciones_baja}
                                onChange={handleInputChangeDarDeAlta}
                                fetchData={fetchDarDeAltaData}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleCloseModalDarDeAlta}>
                        Salir
                    </Button>
                    <ButtonSpinner
                        variant="info" type="submit" className="ms-2"
                        handleSubmit={handleSubmitDarDeAlta} fetchDataLoading={[fetchDarDeAltaData.loading]}
                        value='Dar de alta'
                    />
                </Modal.Footer>
            </Modal>

            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                escalas={[
                    { x: 1, y: 1, label: 'Cuadrado' },
                    { x: 4, y: 2, label: 'Rectángulo' }
                ]}
            />

            <ModalStatusServer fetchData={fetchDarDeBajaData?.data} />

            <ModalStatusServer fetchData={fetchDarDeAltaData?.data} />

            <ModalStatusError fetchData={fetchDocumentoData} />

        </>
    )
}
