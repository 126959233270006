import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import { postUsuarioAdapterSchema } from '../../../adapters/usuario.adapter';
import { PostConsultorJSON } from '../../../services/consultor.service';
import { PostUsuarioJSON } from '../../../services/usuario.service';

// import './ProcessosMain.css'

export const ModalCrearUsuario = ({ showModalCrearUsuario, setshowModalCrearUsuario, handleCloseModalCrearUsuario, fetchRCPData,
    fetchVerificadoresData, fetchIdiomasData, fetchUsuarios }) => {

    const history = useHistory();

    const [fetchCrearUsuario, fetchCrearUsuarioData] = PostUsuarioJSON()

    const [formCrearUsuario, 
        handleInputChangeCrearDAPcons, 
        handleFormChangeCrearDAPcons, 
        handleResetCrearDAPcons] = useForm(postUsuarioAdapterSchema())

    const [valuesValidCrearDAPcons, handleVerifiedValuesCrearDAPcons, handleResetValuesCrearDAPcons] = useValidator({
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        apellidos: { isOk: true, msgError: [`Los apellidos son obligatorio.`] },
        usuario: { isOk: true, msgError: [`El usuario es obligatorio.`] },
        email: { isOk: true, msgError: [`El email es obligatorio`] },
    })

    const handleSubmitAltaDAPcons = () => {

        if (handleVerifiedValuesCrearDAPcons(formCrearUsuario)) {
            fetchCrearUsuario({
                body: {
                    ...formCrearUsuario
                }
            })
        }
    }

    useEffect(() => {
        console.log(fetchCrearUsuarioData)

        if (fetchCrearUsuarioData?.status === 201) {
            fetchUsuarios()
            handleCloseModalCrearUsuario()
        }

    }, [fetchCrearUsuarioData])

    
    return (
        <Modal show={showModalCrearUsuario} onHide={handleCloseModalCrearUsuario} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Ficha de creación de Usuario Caateeb</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>

                <Form className='row'>

                    <Form.Group className="mb-4 col-sm-7 col-lg-3">
                        <InputText
                            label='NIF:'
                            className=''
                            name='usuario'
                            value={formCrearUsuario.usuario}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-6 col-lg-4">
                        <InputText
                            label='Nombre:'
                            className=''
                            name='nombre'
                            value={formCrearUsuario.nombre}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-8 col-lg-5">
                        <InputText
                            label='Apellidos:'
                            className=''
                            name='apellidos'
                            value={formCrearUsuario.apellidos}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-6 col-lg-3">
                        <InputText
                            label='Teléfono:'
                            className=''
                            name='telefono'
                            value={formCrearUsuario.telefono}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-8 col-lg-5">
                        <InputText
                            label='E-mail:'
                            className=''
                            name='email'
                            value={formCrearUsuario.email}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                        />
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" >
                    Salir
                </Button>
                <ButtonSpinner
                    variant="info" type="submit" className="ms-2"
                    handleSubmit={handleSubmitAltaDAPcons} fetchDataLoading={[fetchCrearUsuarioData.loading]}
                    value='Crear Usuario' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                />
            </Modal.Footer>
        </Modal>
    )
}
