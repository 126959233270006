import { cloneDeep } from 'lodash';
import queryString from 'query-string';

export function getQueryStringSearchCollegiats(objParams) {
  return `?${queryString.stringify({
    colegio_id: objParams.colegio_id ? objParams.colegio_id : undefined,
    page: objParams.page !== '' ? objParams.page : undefined
  })}`
}


export function getMsgInputServerColor(objParams, name) {
  return objParams?.error?.response?.data?.data?.[name]
}

export function getMsgInputColor(objParams, name) {
  return objParams[name] && !objParams[name]?.isOk
}

export function getValuesParams(objValuesSearch) {
  let urlParams = {}

  for (const key of Object.keys(objValuesSearch)) {
    if (objValuesSearch[key] !== '') {
      console.log(objValuesSearch[key])
      urlParams[key] = objValuesSearch[key]
    }
  }

  return urlParams
}

export function getURLQuery(objUrlParams) {
  return Object.keys(objUrlParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(objUrlParams[k])).join('&');
}

export function isOrderPrev_(effectTempIndex, effectTempIndex1) {
  return (effectTempIndex.indexOf('orderPrev-') !== -1) || (effectTempIndex1.indexOf('orderPrev-') !== -1)
}

export function breakExistA1A3(exista1a3, index) {
  if (exista1a3 && (index === 'a1' || index === 'a2' || index === 'a3')) {
    return true
  }

  if (!exista1a3 && (index === 'a1a3')) {
    return true
  }
  return false
}

export function breakExistRecuperacion(exista1a3, index) {
  if (exista1a3 && (index === 'kg_para_reutilizacion' || index === 'kg_para_reciclado' || index === 'kg_para_valorizacion_energetica')) {
    return true
  }

  if (!exista1a3 && (index === 'kg_mix_reutilizacion_reciclado_valorizacion_energetica')) {
    return true
  }
  return false
}

export function isAdmin(perfil) {
  return perfil === 'admin_ares'
}

export function isCaateeb(perfil) {
  return perfil === 'admin_caateeb'
}

export function isConsultor(perfil) {
  return perfil === 'consultor'
}

export function isVerificador(perfil) {
  return perfil === 'verificador'
}

export function isOrganizacion(perfil) {
  return perfil === 'organizacion'
}

export function isModuleOrganizacion(modulo_app) {
  return modulo_app === 'organizacion'
}

export function isModuleAdministrador(modulo_app) {
  return modulo_app === 'administrador'
}

export function isModuleConsultor(modulo_app) {
  return modulo_app === 'consultor'
}


export function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

// Helpers para firmado


export function getNavigator() {
  var ua = navigator.userAgent;
  var tem;
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}

export function getUrlGuia(navegador) {

  if (navegador === 'Chrome') {
    return 'guia_chrome'
  }

  if (navegador === 'Firefox') {
    return 'guia_firefox'
  }

  if (navegador === 'Edg') {
    return 'guia_edge'
  }

  return 'guia_chrome'
}

export function getObjectKeysUsed(obj) {

  const objTemp = {}

  for (const key in obj) {
    if (obj[key]) {
      objTemp[key] = obj[key]
    }
  }

  return objTemp

}

export function getObjectKeysMultilineUsed(obj) {

  const objTemp = {}

  for (const key in obj) {
    if (key.indexOf('.') !== -1) {
      objTemp[key] = obj[key]
    }
  }

  return objTemp

}

export function getLabelByModules(array) {

  const size = array.length;

  if (size === 1) {

    return `(${array[0].idUpper})`

  } else if (size === 2) {

    return `(${array[0].idUpper} y ${array[1].idUpper})`

  } else if (size === 3) {

    return `(${array[0].idUpper} , ${array[1].idUpper} y ${array[2].idUpper})`
  }

  return ''
}

export function getLabelByModulesv2(array, valiKeys) {

  const arrayTemp = []

  for (let i = 0; i < valiKeys.length; i++) {

    const result = array.find(item => item.modulo_param_id === valiKeys[i])

    console.log(result)
    if (result) {
      arrayTemp.push(result)
    }
  }

  console.log(array, arrayTemp)
  const size = arrayTemp.length;

  if (size === 1) {

    return `${arrayTemp[0].label}`

  } else if (size === 2) {

    return `${arrayTemp[0].label} y ${arrayTemp[1].label}`

  } else if (size === 3) {

    return `${arrayTemp[0].label} , ${arrayTemp[1].label} y ${arrayTemp[2].label}`

  } else if (size === 4) {

    return `${arrayTemp[0].label} , ${arrayTemp[1].label} , ${arrayTemp[2].label} y ${arrayTemp[3].label}`
  }

  return ''
}

export function getModelos(dapcons_modelos = []) {

  const modelosTemp = dapcons_modelos.map(item => {
    return {
      modelo_id: item.modelo_id,
      modelo_nombre: item.modelo_nombre,
    }
  })

  return modelosTemp
}

export function getFormatDiaMesAnio(fecha) {

  return fecha?.split('-')?.reverse()?.join('/')
}

export function guardarDatos(form, modulo_app) {

  const userRecovery = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_REMEMBER_PASSWORD')) || {}

  userRecovery[modulo_app] = {
    usuario: form.usuario,
    password: form.password,
    modulo_app: form.usuario ? modulo_app : ''
  }

  localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_REMEMBER_PASSWORD', JSON.stringify(userRecovery));
}

export function clipboardMatriz5y6(clipboardParam, matrizParam, filaParamKey, columnaParamKey, exista1a3Param) {

  console.log('matrizTemp', matrizParam, 'clipboardParam', clipboardParam, 'columnaParamKey', columnaParamKey, 'filaParamKey', filaParamKey);

  // Limpiar los \r del clipboard

  let clipboardTemp = cloneDeep(clipboardParam)

  for (let i = 0; i < clipboardTemp.length; i++) {
    for (let j = 0; j < clipboardTemp[i].length; j++) {
      clipboardTemp[i][j] = (clipboardTemp[i][j].replace('\r','')).replace('MND','')
    }
  }

  console.log(clipboardTemp)


  let matrizTemp = cloneDeep(matrizParam)

  let matrizTempKeysFilas = Object.keys(matrizTemp)
  let matrizTempKeysColumnas = Object.keys(matrizTemp[matrizTempKeysFilas[0]])

  // let matrizTempArray = [...Array(matrizTempKeysFilas.length)].map(e => Array(matrizTempKeysColumnas.length))
  let matrizTempArray = []
  for (let i = 0; i < matrizTempKeysFilas.length; i++) {
      let y = []
      for (let j = 0; j < matrizTempKeysColumnas.length; j++) {
          y.push('')
      }
      matrizTempArray.push(y)
  }

  // console.log('matrizTempArray', matrizTempArray);
  for (let i = 0; i < matrizTempKeysFilas.length; i++) {
      for (let j = 0; j < matrizTempKeysColumnas.length; j++) {

          matrizTempArray[i][j] = matrizTemp[matrizTempKeysFilas[i]][matrizTempKeysColumnas[j]]
      }
  }

  let filaParam = matrizTempKeysFilas.findIndex(item => item === filaParamKey)
  let columnaParam = matrizTempKeysColumnas.findIndex(item => item === columnaParamKey)

  // console.log('matrizTempArray', matrizTempArray);

  let m = 0
  let n = 0

  // console.log('clipboardTemp', exista1a3Param, Number(columnaParam), clipboardTemp[0].length)

  // Para saltar la columna 4 que es la de a1a3
  // if (!exista1a3Param && ((Number(columnaParam)) + clipboardTemp[0].length) > 3 && (columnaParam) < 3) {
  //     for (let i = 0; i < clipboardTemp.length; i++) {
  //         clipboardTemp[i].splice(3 - Number(columnaParam), 0, '');
  //     }
  //     console.log('clipboardTemp', clipboardTemp)
  // }

  for (let i = filaParam; i < matrizTempKeysFilas.length; i++) {
      for (let j = columnaParam; j < matrizTempKeysColumnas.length; j++) {

          try {

              if (j < 18) {
                  if (matrizTempArray[i][j] === 'MND' || ( j === 3 && !exista1a3Param)) {
                      // continue
                  } else {

                      if (n < clipboardTemp[0].length) {
                          // console.log(clipboardTemp[m][n]);
                          if (clipboardTemp[m][n]) {

                              matrizTempArray[i][j] = clipboardTemp[m][n]
                          }
                          n++;
                      } else {
                          break;
                      }

                  }
              }
          } catch (error) {
              console.error(error)
          }
      }
      n = 0;
      m++;
      if (m >= clipboardTemp.length) break
  }

  // console.log('matrizTempArray', matrizTempArray);

  let matrizTempObject = {}

  for (let i = 0; i < matrizTempKeysFilas.length; i++) {
      matrizTempObject[matrizTempKeysFilas[i]] = {}
      for (let j = 0; j < matrizTempKeysColumnas.length; j++) {

          matrizTempObject[matrizTempKeysFilas[i]][matrizTempKeysColumnas[j]] = matrizTempArray[i][j]
      }
  }

  // console.log('matrizTempObject', matrizTempObject);

  return matrizTempObject
}

export function isStatusSucess(status) {

  return String(status)?.[0] === '2'
}


export function getDataFile(fetchData, handleReset, nombre = 'default', extension = 'pdf') {

  if (fetchData.data) {
    // 2. Create blob link to download
    const docData = fetchData.data

    const filename = docData?.headers?.['content-disposition']?.split('filename=')?.[1] || `${nombre}.${extension}`
    const blobData = docData?.data

    const instanceBlob = new Blob([blobData])

    // console.log(instanceBlob)
    const date = new Date()

    const url = window.URL.createObjectURL(instanceBlob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}_${filename}`);
    // 3. Append to html page
    document.body.appendChild(link);

    // 4. Force download
    link.click();

    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);

    handleReset()

  }
}
