import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCheckCircle, faFile, faFileExcel, faSort } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import { ModalCrearDAPcons } from './ModalCrearDAPcons';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext';
import { getDataFile, isModuleAdministrador, isModuleOrganizacion } from '../../../helpers/helpers';

import './DAPconsMain.css'
import { imagesFlags } from '../../../helpers/imagesFlags';
import { images } from '../../../helpers/images';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';

export const DAPconsMain = ({ fetchDAPconsData, formValuesSearch, namePage = 'default', handleFormChange, modulo_app,
    estaVerificando, idiomas, fetchIdiomasData, fetchEstadosDAPconsData }) => {

    // console.log('fetchDAPconsData', fetchDAPconsData)
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const Lang = useLang();

    // Crear DAPcons basico

    const [showModalCrearDAPcons, setShowModalCrearDAPcons] = useState(false);

    const handleCloseModalCrearDAPcons = () => setShowModalCrearDAPcons(false)
    const handleShowModalCrearDAPcons = () => setShowModalCrearDAPcons(true);

    const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)

    useEffect(() => {
        fetchRCP()
        fetchVerificadores()
    }, [])

    const [arrayTempDAPcons, setArrayTempDAPcons] = useState([])

    useEffect(() => {
        if (fetchDAPconsData.data) {

            let arrayTemp = fetchDAPconsData?.data?.data?.data?.data

            setArrayTempDAPcons(arrayTemp)

        }
    }, [fetchDAPconsData])


    // console.log(idiomas)
    const [fetchCrearDAPcons, fetchCrearDAPconsData] = useAxios('POST', '/dapcons/alta')

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const handleCloseModalSuccess = () => setShowModalSuccess(false)
    const handleShowModalSuccess = () => setShowModalSuccess(true);

    // useEffect(() => {

    //     if (fetchCrearDAPconsData?.data?.status === 201) {
    //         // setShowSuccess(true)
    //         // fetchCheckForms({
    //         //     params: {
    //         //         idioma_id: idiomaDAPcon
    //         //     }
    //         // })
    //     }

    // }, [fetchCrearDAPconsData])

    useEffect(() => {
        // console.log(fetchCrearDAPconsData)

        if (fetchCrearDAPconsData.data?.status === 201) {
            handleShowModalSuccess()
            handleCloseModalCrearDAPcons()
        }

    }, [fetchCrearDAPconsData])

    const handleClickContinuar = () => {

        history.push(`/DAPcons/${fetchCrearDAPconsData.data?.data?.message?.split(' = ')?.[1]}/1`)
    }


    // Start --- Handle Click Descargar Excel

    const [fetchDAPconsExcel, fetchDAPconsExcelData, resetfetchDAPconsExcelData]
        = useAxios('GET', `/dapcons`, '', 'blob')

    const handleClickDownloadFile = () => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        fetchDAPconsExcel({
            params: {
                ...formValuesSearchTemp,
                to_excel: true
            }
        })
    }

    useEffect(() => {

        if (fetchDAPconsExcelData.data) {
            getDataFile(fetchDAPconsExcelData, resetfetchDAPconsExcelData, 'DAPcons', 'xlsx')
        }
    }, [fetchDAPconsExcelData])

    return (
        <>
            <div className={isModuleOrganizacion(modulo_app) ? 'col-12' : 'col-xl-9'}>
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} {namePage}</h5>
                        </div>
                        {
                            isModuleOrganizacion(modulo_app) && <div className='d-flex justify-content-end ms-auto'>
                                <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                    onClick={handleShowModalCrearDAPcons}
                                >
                                    <FontAwesomeIcon icon={faFile} className='me-2' /> Alta <span className=''>&nbsp;DAPcon</span>
                                </Button>
                            </div>
                        }
                        {
                            isModuleAdministrador(modulo_app) &&
                            <>
                                <ButtonSpinner
                                    variant="outline-success" type="submit" className="ms-3 px-3"
                                    handleSubmit={handleClickDownloadFile} fetchDataLoading={[fetchDAPconsExcelData.loading]}
                                    value='Descargar Excel' icon={<FontAwesomeIcon icon={faFileExcel} className='me-2' />}
                                />
                            </>
                        }

                    </div>

                    {
                        fetchDAPconsData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    arrayTempDAPcons.length > 0 && idiomas?.length > 0 ?

                                        <>
                                            <Table hover bordered responsive className='tableMain tableDAPcons'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>
                                                            {`${Lang('NUM')}. DAPcon`}
                                                        </th>
                                                        {
                                                            !isModuleOrganizacion(modulo_app) &&
                                                            <th className='text-nowrap'>
                                                                <ButtonOrderBy
                                                                    formValuesSearch={formValuesSearch}
                                                                    handleFormChange={handleFormChange}
                                                                    label={`Empresa`}
                                                                    id='nombre_empresa'
                                                                />
                                                            </th>
                                                        }

                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Producto`}
                                                                id='nombre_producto'
                                                            />
                                                        </th>
                                                        {
                                                            isModuleOrganizacion(modulo_app) &&
                                                            <th className='text-nowrap'>Consultor</th>
                                                        }

                                                        {/* <th className='text-nowrap'>Verificador</th> */}

                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Estado`}
                                                                id='dapcons_estado'
                                                            />
                                                        </th>
                                                        <th className=''>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='dapcons_fecha_estado'
                                                                className='text-center'
                                                                disabledNoWrapText={true}
                                                            />
                                                        </th>
                                                        <th className='text-center'>Idioma</th>
                                                        {
                                                            isModuleAdministrador(modulo_app) &&
                                                            <th className='text-nowrap text-center'>
                                                                <img src={images('./ECO_Logo.png')} className="" alt="logo" width='40px' />

                                                            </th>
                                                        }

                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {arrayTempDAPcons.map((item, index) => {
                                                        return <>
                                                            {
                                                                item['dapcons_valores'].map((item2, index2) => {

                                                                    // const estado = 
                                                                    return (
                                                                        <tr key={`${item.id}_${index2}`} className={`${index2 === 0 ? 'tr_new_dapcon' : ''} ${index % 2 !== 0 ? 'tr_odd_dapcon' : ''} ${item.dapcons_estado_param_id === 'pendiente_verificar_rcp' ? 'tr-red' : ''}`}>
                                                                            <td valign='middle'>{item.dapcons_codigo ? item.dapcons_codigo : 'DAPcons ' + item.id}</td>
                                                                            {
                                                                                !isModuleOrganizacion(modulo_app) &&
                                                                                <td valign='middle' className=''>{item.organizacion?.org_nombre}</td>
                                                                            }

                                                                            <td valign='middle'>{item2.dv_nombre_producto}</td>
                                                                            {
                                                                                isModuleOrganizacion(modulo_app) &&
                                                                                <td valign='middle'>{item.consultor?.consultor_nombre + ' ' + item.consultor?.consultor_apellidos}</td>
                                                                            }

                                                                            {/* <td valign='middle'>{item.verificador?.consultor_nombre + ' ' + item.verificador?.consultor_apellidos}</td> */}
                                                                            <td valign='middle'>
                                                                                {item.dapcons_estado_param_id === 'pendiente_verificar_rcp' ?
                                                                                    fetchEstadosDAPconsData.data?.find(item2 => item2.id === 'pendiente_verificar_rcp')?.nombre
                                                                                    :
                                                                                    fetchEstadosDAPconsData.data?.find(item2 => item2.id === item['idiomas']?.[index2]?.dapcons_idioma_estado)?.nombre
                                                                                }
                                                                            </td>
                                                                            <td valign='middle' className='text-nowrap text-center'>{item['idiomas']?.[index2]?.dapcons_fecha_estado?.split(' ')?.[0]}</td>
                                                                            {/* {console.log(idiomas[item['idiomas'][index2]['idioma_id']])} */}
                                                                            <td valign='middle' className='text-nowrap text-center icon-flag'>
                                                                                <img src={imagesFlags(`./${item['idiomas'][index2]['idioma_id']}.png`)} alt="" />
                                                                            </td>
                                                                            {
                                                                                isModuleAdministrador(modulo_app) &&
                                                                                <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item['idiomas'][index2]['inscripcion_ecoplatform'] && 'Si'}
                                                                                </td>
                                                                            }
                                                                            <td valign='middle' className='text-center'>
                                                                                <table>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                {
                                                                                                    (item.dapcons_estado_param_id !== 'pendiente_verificar_rcp' || isModuleAdministrador(modulo_app) || isModuleOrganizacion(modulo_app)) &&
                                                                                                    <OverlayTrigger
                                                                                                        key={item.id + '1'}
                                                                                                        placement={'top'}
                                                                                                        overlay={
                                                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                                                {Lang('VER_MAS')}
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <Button variant="transparent" size="sm" className='btn-modalVermas'
                                                                                                            onClick={() => history.push({
                                                                                                                pathname: `/DAPcons/${item.id}/${item['idiomas'][index2]['idioma_id']}`,
                                                                                                                // state: {
                                                                                                                //     idiomaDAPconURL: item['idiomas'][index2]['idioma_id']
                                                                                                                // }
                                                                                                            })}
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faBook} />
                                                                                                        </Button>
                                                                                                    </OverlayTrigger>
                                                                                                }

                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchDAPconsData?.data?.data?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchDAPconsData?.data?.data?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se han encontrado resultados</h5>
                                }
                            </>
                    }
                </div>
            </div>

            <ModalCrearDAPcons
                showModalCrearDAPcons={showModalCrearDAPcons}
                handleCloseModalCrearDAPcons={handleCloseModalCrearDAPcons}
                fetchRCPData={fetchRCPData}
                fetchVerificadoresData={fetchVerificadoresData}
                fetchIdiomasData={fetchIdiomasData}
                fetchCrearDAPcons={fetchCrearDAPcons}
                fetchCrearDAPconsData={fetchCrearDAPconsData}
            />

            <Modal show={showModalSuccess} onHide={handleCloseModalSuccess} backdrop='static'>
                {/* <Modal.Header closeButton className='border-0 pt-4'>
                    <Modal.Title className='h5'>{title}</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='my-4 fw-normal'>
                            <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                        </h5>
                        <p className=''>
                            Se ha creado el DAPcons con exito.
                        </p>
                        <p className='mb-0'>
                            A partir de este momento el consultor asignado deberá acceder a la plataforma y seguir el procedimiento para introducir los datos necesarios y seguir con el proceso de la DAPcons.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="primary" onClick={handleClickContinuar}>
                        Continuar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalStatusServer2 fetchData={fetchDAPconsExcelData} onlyError/>

        </>
    )
}
