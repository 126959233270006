import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios'
import { useForm } from '../../../hooks/useForm'
import { DAPconsFilter } from '../DAPconsFilter/DAPconsFilter'
import { DAPconsMain } from '../DAPconsMain/DAPconsMain'
import queryString from 'query-string';
import { AuthContext } from '../../../auth/AuthContext'
import { getURLQuery, getValuesParams, isModuleOrganizacion, isModuleConsultor, isVerificador, getFormatDiaMesAnio } from '../../../helpers/helpers'
import { GetParametroJSON } from '../../../services/parametro.service'

export const DAPconsContent = ({namePage, estaVerificando}) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation()

    const [fetchParameters, fetchParametersData] = useAxios('get', `/parametros?idioma=1`)

    useEffect(() => {

        fetchIdiomas()

    }, [])

    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)


    const [idiomas, setIdiomas] = useState([])

    useEffect(() => {

        if (fetchIdiomasData.data?.status === 200) {
            const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                return {
                    id: item.id,
                    nombre: `${item.idioma_nombre}`
                }
            })

            setIdiomas(listTemp)
        }

    }, [fetchIdiomasData])


    let filtroConsultor = ''

    if (isModuleConsultor(modulo_app)) {
        if (estaVerificando) {
            filtroConsultor = '?perfil=verificador'
        } else {
            filtroConsultor = '?perfil=consultor'
        }
    }

    const [fetchDAPcons, fetchDAPconsData] = useAxios('GET', `/dapcons${filtroConsultor}`)

    const {
        estado_id: estado_idParam = '',
        nif: nifParam = '',
        search_text: search_textParam = '',
        page: pageParam = 1,
        order_by: order_byParam = '',
        idioma_id: idiomaParam = '',
        desde: desdeParam = '',
        hasta: hastaParam = '',
        pagado: pagadoParam = '',
        search: searchParam = '',

    } = queryString.parse(location.search);

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        nif: nifParam,
        search_text: search_textParam,
        page: pageParam,
        order_by: order_byParam,
        estado_id: estado_idParam,
        idioma_id: idiomaParam,
        desde: desdeParam,
        hasta: hastaParam,
        pagado: pagadoParam,
        search: searchParam,
    });

    const handleSearch = (e) => {

        if (e) {
            e.preventDefault();
        }

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        console.log(urlParams)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        // history.push(`?${query}`);
        window.history.replaceState(null, null, `?${query}`);

        if (formValuesSearch.page !== 1) {

            handleFormChange({
                ...formValuesSearch,
                page: 1
            });

        } else {

            let formValuesSearchTemp = { ...formValuesSearch }

            Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

            fetchDAPcons({
                params: {
                    ...formValuesSearchTemp
                }
            })
        }
    }

    useEffect(() => {

        // Obtener todos los valores existentes en el form de values Search
        const urlParams = getValuesParams(formValuesSearch)

        // Convertir esos valores del objeto a url amigable
        const query = getURLQuery(urlParams)

        window.history.replaceState(null, null, `?${query}`);

        fetchDAPcons({
            params: {
                ...urlParams
            }
        })


    }, [formValuesSearch.page, formValuesSearch.order_by])

    // Parametro ID

    const [fetchEstadosDAPcons, fetchEstadosDAPconsData] = GetParametroJSON('estados_dapcons')

    useEffect(() => {

        fetchEstadosDAPcons();

    }, [])

    return (
        <>
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            {
                !isModuleOrganizacion(modulo_app) && <DAPconsFilter
                    handleSearch={handleSearch}
                    handleInputChangeSearch={handleInputChangeSearch}
                    formValuesSearch={formValuesSearch}
                    fetchParametersData={fetchParametersData}
                    fetchEstadosDAPconsData={fetchEstadosDAPconsData}
                    idiomas={idiomas}
                    estaVerificando={estaVerificando}
                />
            }

            <DAPconsMain
                fetchDAPconsData={fetchDAPconsData}
                formValuesSearch={formValuesSearch}
                namePage={namePage}
                handleFormChange={handleFormChange}
                fetchParametersData={fetchParametersData}
                modulo_app={modulo_app}
                estaVerificando={estaVerificando}
                idiomas={idiomas}
                fetchIdiomasData={fetchIdiomasData}
                fetchEstadosDAPconsData={fetchEstadosDAPconsData}
            />
        </>
    )
}
