
// --------------- listado de Usuarios Caateeb

export const usuariosAdapterSchema = (item = {}) => {

    const data = {
        id: item.id || '',
        usuario: item.usuario || '',
        nombre: item.usuarios_administradores?.[0]?.nombre || '',
        apellidos: item.usuarios_administradores?.[0]?.apellidos || '',
        telefono: item.usuarios_administradores?.[0]?.telefono || '',
        // es_verificador: item.es_verificador || false,
    }

    return {
        ...data,
        nombres_completo: `${data.nombre} ${data.apellidos}`,
        // es_verificador: data.es_verificador ? 'Si' : 'No',
    }
}

export const usuariosAdapter = (data) => {

    const dataTemp = data?.data.data

    // console.log(dataTemp?.data)

    const items = dataTemp?.data?.map(item => (usuariosAdapterSchema(item)))

    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        },
        status: data?.status
    }
}

// ---------------- Objeto GET Usuario

export const getUsuarioAdapterSchema = (item = {}) => {

    const data = {
        activo: item.activo || '',
        apellidos: item.apellidos || '',
        email: item.email || '',
        fecha_creacion: item.fecha_creacion || '',
        fecha_modificacion: item.fecha_modificacion || '',
        id: item.id || '',
        nombre: item.nombre || '',
        telefono: item.telefono || '',
        usuario: item.usuario,
    }

    return {
        ...data
    }
}

export const getUsuarioAdapter = (data) => {

    const dataTemp = data?.data?.data

    // console.log(dataTemp)

    // const items = dataTemp?.data?.map(item => (consultorAdapterSchema(item)))
    const item = getUsuarioAdapterSchema(dataTemp)

    return {
        data: item,
        status: data?.status
    }
}


// // --------------- Objeto POST Usuario

export const postUsuarioAdapterSchema = (item = {}) => {

    const data = {
        nombre: item.nombre || '',
        apellidos: item.apellidos || '',
        usuario: item.usuario || '',
        telefono: item.telefono || '',
        email: item.email || '',
    }

    return data
}

export const postUsuarioAdapter = (data) => {

    const item = postUsuarioAdapterSchema(data)

    return {
        ...item
    }
}


// ----------------------- Mis Datos ------------------------

export const getUsuarioAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        id: item.id || '',
        logo: item.consultor_logo || '',
        nif: item.consultor_nif || '',
        nombre: item.consultor_nombre || '',
        apellidos: item.consultor_apellidos || '',
        empresa_nombre: item.consultor_empresa_nombre || '',
        direccion: item.consultor_direccion || '',
        codigo_postal: item.consultor_codigo_postal || '',
        movil: item.consultor_movil || '',
        email: item.consultor_email || '',
        poblacion: item.consultor_poblacion || '',
        es_verificador: item.es_verificador || false,
        usuario: item.usuario_id || '',
        verificador_casillas: item.verificador_casillas || [],
    }

    return {
        ...data
    }
}

export const getConsultorAdapterMisDatos = (data) => {

    const dataTemp = data?.data?.data
    
    const item = getUsuarioAdapterSchemaMisDatos(dataTemp)

    return {
        data: item,
        status: data?.status
    }
}


export const postConsultorAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        id: item.id || '',
        consultor_logo: item.logo || '',
        consultor_nif: item.nif || '',
        consultor_nombre: item.nombre || '',
        consultor_apellidos: item.apellidos || '',
        consultor_empresa_nombre: item.empresa_nombre || '',
        consultor_direccion: item.direccion || '',
        consultor_codigo_postal: item.codigo_postal || '',
        consultor_movil: item.movil || '',
        consultor_email: item.email || '',
        consultor_poblacion: item.poblacion || '',
        casillas: item.verificador_casillas || '',
        es_verificador: item.es_verificador || false,
    }

    return data
}


export const postConsultorAdapterMisDatos = (form) => {

    const formAdapter = postConsultorAdapterSchemaMisDatos(form)

    console.log(form, formAdapter)

    let formData = new FormData();

    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
        // if(key === 'casillas') {
             
        //     formData.append(key, JSON.stringify(formAdapter[key]))
        // } else {

        // }
    }

    return formData
}
