import React, { useEffect, useState, useRef } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap'

export const ModalAlerta = ({ data, handleCloseModal}) => {
    return (
        <Modal show={data.value} onHide={handleCloseModal} size="lg" centered>
            
            <Modal.Body className='px-4 py-4'>
            <Alert variant="info">
            <p>
                Os informamos que del 5 al 28 de agosto de 2024 el servicio permanecerá cerrado. Durante este periodo podéis utilizar la plataforma con normalidad y las consultas técnicas serán resueltas a partir del 28 de agosto.
            </p>

            <p>En caso de incidencias informáticas, consultas técnicas, problemas de acceso o errores en el sistema podéis contactar a través de sostenible@cateb.cat, serán tratadas y resueltas a partir del 28 de agosto.</p>
            </Alert>
            <div className="col-12 text-center">
                 <Button variant="primary"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </div>
            </Modal.Body>
        </Modal>
    )
}
